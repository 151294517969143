import axios from "axios";
import { authHeader, getServerinfo } from "../functions/Auth";

/*axios.interceptors.request.use(function (config) {
    const token = getJWToken;
    config.headers.common['Authorization'] =  token;

    return config;
});*/
const api_url = getServerinfo().base_url;
const serverHost = getServerinfo().host;
const serverPort = getServerinfo().port;

const instance = axios.create({
    baseURL: api_url,
});

const myInterceptor = instance.interceptors.request.use(function (config) {
    config.proxy.host = serverHost;
    config.proxy.port = serverPort;

    return config;
});
instance.interceptors.request.eject(myInterceptor);

export const login = user => {
    const formdata = new FormData();
    formdata.append('email', user.email);
    formdata.append('password', user.password);

    return instance({
        method: 'post',
        url: 'login',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {
        const userData = resposnse.data;
        if(userData.status === true) {
            const user = {
                business_name: userData.data.business_name,
                email: userData.data.email,
                first_name: userData.data.first_name,
                last_name: userData.data.last_name,
                userId: userData.data.id,
                phone: userData.data.phone,
                profile_photo_url: userData.data.profile_photo_url,
                user_type: userData.data.user_type,
                isLocked: userData.data.is_locked,
                isVerified: userData.data.is_verified,
                // address: userData.data.address,
                business: {
                    name: (typeof userData.data.business !== 'undefined') ? userData.data.business.name : '',
                    email: (typeof userData.data.business !== 'undefined') ? userData.data.business.email : '',
                    phone: (typeof userData.data.business !== 'undefined') ? userData.data.business.phone : '',
                    address_line_1: (typeof userData.data.business !== 'undefined') ? userData.data.business.address_line_1 : '',
                    address_line_2: (typeof userData.data.business !== 'undefined') ? userData.data.business.address_line_2 : '',
                    country: (typeof userData.data.business !== 'undefined') ? userData.data.business.country : '',
                    state: (typeof userData.data.business !== 'undefined') ? userData.data.business.state : '',
                    city: (typeof userData.data.business !== 'undefined') ? userData.data.business.city : '',
                    zip_code: (typeof userData.data.business !== 'undefined') ? userData.data.business.zip_code : '',
                    discount: (typeof userData.data.business !== 'undefined') ? userData.data.business.discount : '',
                }
            }

            localStorage.setItem('user', JSON.stringify(user));
        }
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    });    
}

export const register = newUser => {
    const formdata = new FormData();
    formdata.append('email', newUser.email);
    formdata.append('password', newUser.password);
    formdata.append('phone', newUser.phone);
    formdata.append('user_type', newUser.user_type);
    if(newUser.user_type == 'enterprise'){
        formdata.append('business_name', newUser.business_name);        
        const address = newUser.business_address;
        formdata.append('business_address[cp_address_id]', address.cp_address_id);
        formdata.append('business_address[city]', address.city);
        formdata.append('business_address[country_code]', address.country_code);
        formdata.append('business_address[country_name]', address.country_name);
        formdata.append('business_address[full_address]', address.full_address);
        formdata.append('business_address[post_code]', address.post_code);
        formdata.append('business_address[state_code]', address.state_code);
        formdata.append('business_address[state_name]', address.state_name);
        formdata.append('business_address[street1]', address.street1);
        formdata.append('business_address[street2]', address.street2);
        formdata.append('business_address[street3]', address.street3);
    }

    return instance({
        method: 'post',
        url: 'register_user',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    });    
    // return axios
    //     .post('register_user', newUser)
    //     .then(resposnse => {
    //         //console.log(resposnse);
            
    //         return resposnse.data;
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
}

// export const getProfile = () => {
//     // axios.defaults.headers.common['Authorization'] = authHeader();
//     return axios
//         .get('user/profile')
//         .then(resposnse => {
//             //console.log(resposnse);

//             return resposnse.data;
//         })
//         .catch(err => {
//             console.log(err);
//         })
// }

export const updateProfile = profile => {
    const formdata = new FormData();
    formdata.append('user_id', profile.user_id);
    formdata.append('userType', profile.userType);
    formdata.append('phone', profile.phone);
    formdata.append('first_name', profile.first_name);
    formdata.append('last_name', profile.last_name);
    formdata.append('business_name', profile.business_name);
    if(profile.userType == '2'){
        const address = profile.business_address;
        formdata.append('business_address[address_id]', address.address_id);
        formdata.append('business_address[cp_address_id]', address.cp_address_id);
        formdata.append('business_address[city]', address.city);
        formdata.append('business_address[country_code]', address.country_code);
        formdata.append('business_address[country_name]', address.country_name);
        formdata.append('business_address[full_address]', address.full_address);
        formdata.append('business_address[post_code]', address.post_code);
        formdata.append('business_address[state_code]', address.state_code);
        formdata.append('business_address[state_name]', address.state_name);
        formdata.append('business_address[street1]', address.street1);
        formdata.append('business_address[street2]', address.street2);
        formdata.append('business_address[street3]', address.street3);
    } else {
        formdata.append('business_address', profile.business_address);
    }

    return instance({
        method: 'post',
        url: 'update_profile',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {
        const userData = resposnse.data;
        if(userData.status === true) {
            const user = {
                business_name: userData.data.business_name,
                email: userData.data.email,
                first_name: userData.data.first_name,
                last_name: userData.data.last_name,
                userId: userData.data.id,
                phone: userData.data.phone,
                profile_photo_url: userData.data.profile_photo_url,
                user_type: userData.data.user_type,
                isLocked: userData.data.is_locked,
                isVerified: userData.data.is_verified,
                address: userData.data.address,
            }

            localStorage.setItem('user', JSON.stringify(user));
        }
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    });   
}

export const changePassword = passwordData => {
    const formdata = new FormData();
    formdata.append('user_id', passwordData.user_id);
    formdata.append('new_password', passwordData.new_password);
    formdata.append('old_password', passwordData.old_password);

    return instance({
        method: 'post',
        url: 'change_password',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {        
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    });   
    
}