import React from "react";

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";

//sections
import Banner from "./components/Home/Banner";
import HowWorks from "./components/Home/HowWorks";
import Features from "./components/Home/Features";
import CallToAction from "./components/Home/CallToAction";
import Testimonial from "./components/Home/Testimonial";
import DownloadApp from "./components/Home/DownloadApp";
import Partners from "./components/Home/Partners";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("home");
    return function cleanup() {
      document.body.classList.remove("home");
    };
  });

  return (
    <>
        <Header/>
        <Banner/>
        <HowWorks/>
        <Features/>
        <CallToAction/>
        <Testimonial/>
        <DownloadApp/>
        <Partners/>
        <Footer/>
    </>
  );
}

export default Home;
