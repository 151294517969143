import React from "react";
import {
    Container,
    Row,
    Col,
    Media
} from "reactstrap";

function Features() {
    return (
        <>

            <section className="features-section py-4 py-md-5 bg-light">
                <Container>
                    <div className="title mb-2 mb-md-4 text-center">
                        <h2 className="title-text">Shipping above and beyond</h2>
                        <p className="lead">Our additional services will have you shipping like a boss, pronto</p>
                    </div>

                    <Row className="mt-5">
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-wallet"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase">Transparent Pricing</h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-speedometer"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase">Fast &amp; Efficient Delivery </h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-credit-card"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase"> Easy Payment Methods </h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-speech"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase"> 24/7 Hours Support </h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-settings"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase">Easy Customization</h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md={4} className="features-module mb-4">
                            <Media>
                                <div className="features-icon mr-4 text-primary la-3x">
                                    <i className="icon-screen-desktop"></i>
                                </div>
                                <Media body>
                                    <h6 className="text-uppercase">Easy Customization</h6>
                                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel cras sit amet nibh libero,
                                        in gravida nulla. Nulla vel </p>
                                </Media>
                            </Media>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
}

export default Features;