import React from "react";
import themeStyle from "../components/general/ThemeStyle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Media,
} from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";

import Partners from "../components/Home/Partners";
import AlertMessages from "../components/general/AlertMessages";
import { contactUs } from "../../functions/APIRequest";

// function ContactUs() {
class ContactUs extends React.Component {
  constructor() {
    super();

    this.state = {
      showMessage: "",
      isLoading: false,
      messageColor: "",

      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      companyName: "",
      sendMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("contact-us");

    document.documentElement.classList.remove("nav-open");
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isLoading: true });
    let form_data = {};
    if (event.target.length > 0) {
      for (var i = 0; i < event.target.length; i++) {
        const element = event.target[i];
        if (element.name !== "") {
          form_data[element.name] = element.value;
        }
      }
    }

    contactUs(form_data).then((res) => {
      if (res) {
        this.setState({ messageColor: res.status ? "success" : "danger" });
        this.setState({
          showMessage: !res.status
            ? "opps! Something went wrong. Plese try after some tiems."
            : "Thank you for your contacting us. We will reach you soon.",
        });

        setTimeout(
          function () {
            this.setState({ showMessage: "" });
            this.setState({ messageColor: "" });
          }.bind(this),
          5000
        );

        this.setState({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          companyName: "",
          sendMessage: "",
          isLoading: false,
        });
      }
    });
  }

  render() {
    return (
      <>
        <Header />

        <div className="pageheader-wrapper py-5">
          <Container>
            <h2 className="banner-title mb-md-3 text-white">Contact Us</h2>
          </Container>
        </div>

        <section className="contact-section py-5">
          <Container>
            <div className="title mb-2 mb-md-4 text-center">
              <h2 className="title-text">Shipping above and beyond</h2>
              <p className="lead">
                Spend less on shipping, so you can spend more on your growing
                business.
              </p>
            </div>

            <Card>
              <CardBody>
                <Row className="justify-content-between">
                  <Col md={6} className="mb-4">
                    <div className="mb-4">
                      <h5>We love to hear from you</h5>
                      <hr />
                    </div>

                    {this.state.showMessage !== "" ? (
                      <AlertMessages
                        color={this.state.messageColor}
                        message={this.state.showMessage}
                      />
                    ) : (
                      ""
                    )}

                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleSubmit}
                      onError={(errors) => console.log(errors)}
                    >
                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="First Name"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="firstName"
                          id="firstName"
                          value={this.state.firstName}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Last Name"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="lastName"
                          id="lastName"
                          value={this.state.lastName}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Phone"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="phone"
                          id="phone"
                          value={this.state.phone}
                          validators={[
                            "required",
                            "matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$",
                          ]}
                          errorMessages={[
                            "this field is required",
                            "Please enter valid phone.",
                          ]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Email"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="email"
                          id="email"
                          margin="normal"
                          value={this.state.email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "this field is required",
                            "email is not valid",
                          ]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Company Name"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="companyName"
                          id="companyName"
                          value={this.state.companyName}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Message"
                          onChange={this.onChange}
                          multiline
                          rows="3"
                          style={themeStyle.textField}
                          name="sendMessage"
                          id="sendMessage"
                          value={this.state.sendMessage}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Button
                          color="success"
                          className="btn-block"
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading ? "Loading…" : "Send"}
                        </Button>
                      </FormGroup>
                    </ValidatorForm>
                  </Col>

                  <Col md={5} className="mb-4">
                    <h5>Contacts</h5>
                    <hr />

                    <Media className="pt-4">
                      <Media left className="mr-4">
                        <i className="icon-envelope text-primary"></i>
                      </Media>
                      <Media body>
                        <div className="contact-widget">
                          <span className="mail-caption">
                            General Inquiries
                          </span>
                          <a href="mailto:inquire@etapali.com">
                            inquire@shipeazy.ca
                          </a>
                        </div>
                      </Media>
                    </Media>

                    <Media className="pt-4">
                      <Media left className="mr-4">
                        <i className="icon-envelope text-primary"></i>
                      </Media>
                      <Media body>
                        <div className="contact-widget">
                          <span className="mail-caption">Sales</span>
                          <a href="mailto:sales@etapali.com">
                            sales@shipeazy.ca
                          </a>
                        </div>
                      </Media>
                    </Media>

                    <Media className="pt-4">
                      <Media left className="mr-4">
                        <i className="icon-envelope text-primary"></i>
                      </Media>
                      <Media body>
                        <div className="contact-widget">
                          <span className="mail-caption">Operation</span>
                          <a href="mailto:operations@etapali.com">
                            operations@shipeazy.ca
                          </a>
                        </div>
                      </Media>
                    </Media>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>

        <Partners />
        <Footer />
      </>
    );
  }
}

export default ContactUs;
