import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";

import CallToAction from "../components/Home/CallToAction";
import Testimonial from "../components/Home/Testimonial";
import DownloadApp from "../components/Home/DownloadApp";
import Partners from "../components/Home/Partners";

class AboutUs extends Component {
  /*document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("about-us");
        return function cleanup() {
            document.body.classList.remove("about-us");
        };
    });*/

  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      members: [],
    };
  }

  componentDidMount() {
    let self = this;
    fetch("/users")
      .then((res) => res.json())
      .then((members) => self.setState({ members: members }));
  }

  render() {
    return (
      <>
        <Header />

        <div className="pageheader-wrapper py-5">
          <Container>
            <h2 className="banner-title mb-md-3 text-white">About Us</h2>
          </Container>
        </div>

        <section className="about-section">
          <div className="about-bototm bg-light py-5">
            <Container>
              <Row className="justify-content-between align-items-center">
                <Col lg={4}>
                  <img
                    className="img-fluid img-thumbnail"
                    src={require("../../assets/images/assets/about-us.jpg")}
                    alt="About Shipeazy"
                  />
                </Col>
                <Col lg={7}>
                  <div className="about_content">
                    <div className="title mb-2 mb-md-4">
                      <h2 className="title-text">
                        Shipeazy is your integrated shipping solution in the
                        cloud
                      </h2>
                      <p className="lead">
                        Whether you're connecting your store or manually
                        entering
                        <br />
                        your orders, you can start shipping in minutes
                      </p>
                    </div>
                    <p>
                      Shipeazy only works with the most reputable carriers in
                      the world to give our customers the best possible shipping
                      services and rates available. Rates and services are
                      automated on our platform so all you have to do is enter
                      your shipping information, select a rate and schedule a
                      pickup with our courier services in Canada and around the
                      world.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="about-top py-5">
            <Container>
              <div className="title mb-2 mb-md-4 text-center">
                <h2 className="title-text">Shipping above and beyond</h2>
                <p className="lead">
                  Spend less on shipping, so you can spend more on your growing
                  business.
                </p>
              </div>
              <Row className="mt-5">
                <Col md={6} className="mb-4">
                  <Card className="h-100">
                    <CardBody>
                      <Media className="pt-4">
                        <Media left className="mr-4">
                          <i className="icon-trophy la-4x text-primary"></i>
                        </Media>
                        <Media body>
                          <h4 className="font-weight-bold mb-4">
                            {" "}
                            Our Mission
                          </h4>
                          <p>
                            At Shipeazy, our mission is to tear down the last
                            barrier of eCommerce: shipping. We believe that any
                            business, regardless of size, should be able to sell
                            worldwide.
                          </p>

                          <p>
                            Our team operates working around the clock to
                            provide shipping solutions that are constantly
                            updated, cutting-edge, and tailor-made for eCommerce
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={6} className="mb-4">
                  <Card className="h-100">
                    <CardBody>
                      <Media className="pt-4">
                        <Media left className="mr-4">
                          <i className="icon-diamond la-4x text-primary"></i>
                        </Media>
                        <Media body>
                          <h4 className="font-weight-bold mb-4"> Our Vision</h4>
                          <p>
                            While working with eCommerce sellers, we realized
                            that worldwide shipping was the last barrier
                            standing for businesses to go global. After
                            experiencing first-hand the frustrations eCommerce
                            sellers had, we knew there had to be a better way to
                            ship worldwide. And so, Shipeazy was born
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <CallToAction />
        <Testimonial />
        <DownloadApp />
        <Partners />
        <Footer />
      </>
    );
  }
}

export default AboutUs;
