import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

function CallToAction() {
  return (
    <>
      <section className="section-cta py-4 py-md-5 bg-primary">
        <Container>
          <Row className="align-items-center">
            <Col lg={8}>
              <div className="cta-content">
                <h2 className="title-text text-white">Trusted by all Canada</h2>
                <p className="lead text-white">
                  Every day, thousands of people use Shipeazy to grow their
                  business
                </p>
              </div>
            </Col>
            <Col>
              <div className="call-btn d-lg-flex justify-content-end">
                <Link to="/contact-us" className="btn btn-white btn-lg btn-cta">
                  Contact Us
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default CallToAction;
