import React from "react";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import themeStyle from '../components/general/ThemeStyle';
import { checkJWToken, getDecodedJWToken } from '../../functions/Auth';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroup,
    Button,
} from "reactstrap";

import Header from "../components/general/Header";
import UserSidebar from "./UserSidebar";
import Footer from "../components/general/Footer";
import AlertMessages from "../components/general/AlertMessages";
import AutoCompleteAddress from "../components/general/AutoCompleteAddress";

import { getProfile, updateProfile } from "../../functions/userRequests";

class MyAccount extends React.Component {
    constructor() {
        super();
        this.state = {
            showMessage: '',
            isLoading: false,
            messageColor: '',
            email: '',
            userId: 0,
            phone: '',
            firstName: '',
            lastName: '',
            businessName: '',
            businessAddress: '',
            businessAddessId: 0,
            userType: '',
            fullname: '',
            errors: {},
            updatedUserData: {},
            selectCountry: ''
        }

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        document.body.classList.add('my-account');

        if (!checkJWToken()) {
            this.props.history.push('/login');
        }

        var userData = getDecodedJWToken();

        if (userData) {
            var firstName = (userData.first_name) ? userData.first_name : '';
            var lastName = (userData.last_name) ? userData.last_name : '';
            this.setState({
                userId: userData.userId,
                email: userData.email,
                phone: userData.phone,
                firstName: userData.first_name,
                lastName: userData.last_name,
                businessName: userData.business_name,
                businessAddress: userData.address,
                // businessAddessId: (userData.address !== null) ? userData.address.id : 0,
                fullname: firstName + " " + lastName,
                userType: userData.user_type,
                // selectCountry: (userData.address !== null) ? userData.address.country_code : ''
            });
        } else {
            this.props.history.push('/login');
        }
    }
    componentWillUnmount() {
        document.body.classList.remove('my-account');
    }

    handleSubmit(e) {
        e.preventDefault();

        let myFormData = {};
        if (e.target.length > 0) {
            for (var i = 0; i < e.target.length; i++) {
                const element = e.target[i];
                if (element.name !== "") {
                    myFormData[element.name] = element.value;
                }
            }
        }

        let businessAddressData = '';
        if( this.state.userType === '2') {
            businessAddressData = {
                address_id: this.state.businessAddessId,
                cp_address_id: myFormData.businessAddress_address_id,
                street1: myFormData.businessAddress_street1,
                street2: myFormData.businessAddress_street2,
                street3: myFormData.businessAddress_street3,
                city: myFormData.businessAddress_city,
                state_code: myFormData.businessAddress_state_code,
                state_name: myFormData.businessAddress_state_name,
                post_code: myFormData.businessAddress_post_code,
                country_code: myFormData.businessAddress_country_code,
                country_name: myFormData.businessAddress_country_name,
                full_address: myFormData.businessAddress,
            };
        }
        
        const profile = {
            phone: this.state.phone,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            business_name: this.state.businessName,
            business_address: businessAddressData,
            userType: this.state.userType,
            user_id: this.state.userId
        }

        this.setState({ isLoading: true });
        updateProfile(profile).then(res => {
            if (res) {
                this.setState({ messageColor: (res.status) ? 'success' : 'danger', showMessage: res.message });
                if (res.status) {
                    var firstName = (res.data.first_name) ? res.data.first_name : '';
                    var lastName = (res.data.last_name) ? res.data.last_name : '';
                    this.setState({
                        userId: res.data.id,
                        phone: res.data.phone,
                        firstName: res.data.first_name,
                        lastName: res.data.last_name,
                        businessName: res.data.business_name,
                        businessAddress: res.data.address,
                        fullname: firstName + " " + lastName,
                        userType: res.data.user_type,
                        updatedUserData: getDecodedJWToken()
                    });
                }
                setTimeout(function () {
                    this.setState({ showMessage: '', messageColor: '' });
                }.bind(this), 5000);
                this.setState({ isLoading: false });
            }
        })
    }

    render() {
        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">My Account</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row>

                            <UserSidebar location={this.props.location} updatedUserData={this.state.updatedUserData} />

                            <Col md={8}>
                                <div className="sidebar-widgets">

                                    {this.state.showMessage !== "" ?
                                        <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                        : ''}

                                    <Card className="border-0 rounded-0 mb-4">
                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h5" className="mb-0">Personal Information</CardTitle>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <ValidatorForm
                                                ref="form"
                                                onSubmit={this.handleSubmit}
                                                onError={errors => console.log(errors)}
                                            >
                                                <Row>
                                                    <div className="col">
                                                        <FormGroup className="fancy-form-group">
                                                            <TextValidator
                                                                label="First Name"
                                                                onChange={this.onChange}
                                                                style={themeStyle.textField}
                                                                name="firstName"
                                                                id="firstName"
                                                                value={this.state.firstName}
                                                                validators={['required']}
                                                                errorMessages={['this field is required']}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col">
                                                        <FormGroup className="fancy-form-group">
                                                            <TextValidator
                                                                label="Last Name"
                                                                onChange={this.onChange}
                                                                style={themeStyle.textField}
                                                                name="lastName"
                                                                id="lastName"
                                                                value={this.state.lastName}
                                                                validators={['required']}
                                                                errorMessages={['this field is required']}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className="col">
                                                        <FormGroup className="fancy-form-group">
                                                            <TextValidator
                                                                label="Email"
                                                                onChange={this.onChange}
                                                                style={themeStyle.textField}
                                                                name="email"
                                                                id="email"
                                                                disabled={true}
                                                                value={this.state.email}
                                                                validators={['required', 'isEmail']}
                                                                errorMessages={['this field is required', 'email is not valid']}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col">
                                                        <FormGroup className="fancy-form-group">
                                                            <TextValidator
                                                                label="Phone"
                                                                onChange={this.onChange}
                                                                style={themeStyle.textField}
                                                                name="phone"
                                                                id="phone"
                                                                value={this.state.phone}
                                                                validators={['required', 'matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$']}
                                                                errorMessages={['this field is required', 'Please enter valid phone.']}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Row>

                                                {this.state.userType === '2' ?
                                                    <Row>
                                                        <div className="col">
                                                            <FormGroup className="fancy-form-group">
                                                                <TextValidator
                                                                    label="Business Name"
                                                                    onChange={this.onChange}
                                                                    style={themeStyle.textField}
                                                                    name="businessName"
                                                                    id="businessName"
                                                                    value={this.state.businessName}
                                                                    validators={['required']}
                                                                    errorMessages={['this field is required']}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col">
                                                            <FormGroup className="fancy-form-group">
                                                                <FormControl style={themeStyle.textField}>
                                                                    <SelectValidator
                                                                        label="Country"
                                                                        value={this.state.selectCountry ?  this.state.selectCountry : ''}
                                                                        onChange={this.onChange}
                                                                        validators={['required']}
                                                                        errormessages={['this field is required']}
                                                                        inputProps={{
                                                                            name: 'selectCountry',
                                                                            id: 'selectCountry',
                                                                        }}
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value="US">
                                                                            United States
                                                                        </MenuItem>
                                                                        <MenuItem value="CA">
                                                                            Canada
                                                                    </MenuItem>
                                                                    </SelectValidator>
                                                                </FormControl>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <FormGroup className="fancy-form-group">
                                                                <AutoCompleteAddress title='Business Address' name="businessAddress" id="businessAddress" selectedAddress={this.state.businessAddress} country={this.state.selectCountry} />
                                                            </FormGroup>
                                                        </div>
                                                    </Row>

                                                    :
                                                    ''
                                                }

                                                <div className="pt-3">
                                                    <Button
                                                        color="success"
                                                        className="btn-block"
                                                        disabled={this.state.isLoading}
                                                    >
                                                        {this.state.isLoading ? 'Loading…' : 'Update'}
                                                    </Button>
                                                </div>
                                            </ValidatorForm>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default MyAccount;