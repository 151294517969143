import React from "react";
import { Container, Card, Media } from "reactstrap";
import OwlCarousel from "react-owl-carousel";
/*import 'owl.carousel/dist/assets/owl.carousel.css';*/
/*import 'owl.carousel/dist/assets/owl.theme.default.css';*/
import "assets/css/owl.carousel.min.css";

function Testimonial() {
  return (
    <>
      <section className="testimonials-section py-5">
        <Container>
          <div className="title mb-2 mb-md-4 text-center">
            <h2 className="title-text">What our users say</h2>
            <p className="lead">
              They love it. Read what our customers had to say!
            </p>
          </div>
          <div className="testimonials-slider mt-5">
            <OwlCarousel
              className="testimonials-carousel owl-theme"
              loop
              margin={15}
              autoplay
              items={1}
            >
              <div className="item p-4">
                <Card className="border-0 rounded-0 p-4">
                  <div className="item">
                    <Media className="testimonials-media d-block d-md-flex">
                      <Media left>
                        <div className="author-image mr-4">
                          <img
                            src={require("../../../assets/images/assets/author.png")}
                            className="img-fluid"
                            alt="author"
                          />
                        </div>
                      </Media>
                      <Media body>
                        <div className="testimonials-text">
                          <p>
                            Shipeazy team is the best of the best, and expertly
                            trained team members who take the extra step and go
                            the extra mile, all to fulfill our dedicated promise
                            to deliver innovative and dynamic solutions to our
                            customers to fit the needs of a rapidly changing
                            global environment.
                          </p>
                        </div>
                        <div className="testimonials-author">
                          <span className="testimonials-author-text text-primary">
                            John Grinwild
                          </span>
                          <span className="designation">Toronto</span>
                        </div>
                      </Media>
                    </Media>
                  </div>
                </Card>
              </div>
              <div className="item p-4">
                <Card className="border-0 rounded-0 p-4">
                  <div className="item">
                    <Media className="testimonials-media d-block d-md-flex">
                      <Media left>
                        <div className="author-image mr-4">
                          <img
                            src={require("../../../assets/images/assets/author.png")}
                            className="img-fluid"
                            alt="author"
                          />
                        </div>
                      </Media>
                      <Media body>
                        <div className="testimonials-text">
                          <p>
                            Shipeazy team is the best of the best, and expertly
                            trained team members who take the extra step and go
                            the extra mile, all to fulfill our dedicated promise
                            to deliver innovative and dynamic solutions to our
                            customers to fit the needs of a rapidly changing
                            global environment.
                          </p>
                        </div>
                        <div className="testimonials-author">
                          <span className="testimonials-author-text text-primary">
                            John Grinwild
                          </span>
                          <span className="designation">Toronto</span>
                        </div>
                      </Media>
                    </Media>
                  </div>
                </Card>
              </div>

              <div className="item p-4">
                <Card className="border-0 rounded-0 p-4">
                  <div className="item">
                    <Media className="testimonials-media d-block d-md-flex">
                      <Media left>
                        <div className="author-image mr-4">
                          <img
                            src={require("../../../assets/images/assets/author.png")}
                            className="img-fluid"
                            alt="author"
                          />
                        </div>
                      </Media>
                      <Media body>
                        <div className="testimonials-text">
                          <p>
                            Shipeazy team is the best of the best, and expertly
                            trained team members who take the extra step and go
                            the extra mile, all to fulfill our dedicated promise
                            to deliver innovative and dynamic solutions to our
                            customers to fit the needs of a rapidly changing
                            global environment.
                          </p>
                        </div>
                        <div className="testimonials-author">
                          <span className="testimonials-author-text text-primary">
                            John Grinwild
                          </span>
                          <span className="designation">Toronto</span>
                        </div>
                      </Media>
                    </Media>
                  </div>
                </Card>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Testimonial;
