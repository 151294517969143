import React from "react";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import classnames from 'classnames';
import { Link } from "react-router-dom";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import themeStyle from "./components/general/ThemeStyle";
import { register } from "functions/userRequests";
import AlertMessages from "./components/general/AlertMessages";
import AutoCompleteAddress from "./components/general/AutoCompleteAddress";

class Register extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            normalEmail: '',
            normalPhone: '',
            normalPassword: '',
            normalConfirmPassword: '',
            businessEmail: '',
            businessPhone: '',
            businessAddress: '',
            businessName: '',
            businessPassword: '',
            businessConfirmPassword: '',
            showMessage: '',
            isLoading: false,
            messageColor: '',
            selectCountry: ''
        };

        this.onChange = this.onChange.bind(this);
        this.normailUserSubmit = this.normailUserSubmit.bind(this);
        this.businessUserSubmit = this.businessUserSubmit.bind(this);
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.normalPassword) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isBusinessPasswordMatch', (value) => {
            if (value !== this.state.businessPassword) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isBusinessPasswordMatch');
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                normalEmail: '',
                normalPhone: '',
                normalPassword: '',
                normalConfirmPassword: '',
                businessEmail: '',
                businessPhone: '',
                businessAddress: '',
                businessName: '',
                businessPassword: '',
                businessConfirmPassword: '',
            });
        }
    }

    normailUserSubmit(e) {
        e.preventDefault();

        const formData = {
            email: this.state.normalEmail,
            phone: this.state.normalPhone,
            password: this.state.normalPassword,
            user_type: 'individual'
        }

        this.callAction(formData);
    }

    businessUserSubmit(e) {
        e.preventDefault();

        let myFormData = {};
        if (e.target.length > 0) {
            for (var i = 0; i < e.target.length; i++) {
                const element = e.target[i];
                if (element.name !== "") {
                    myFormData[element.name] = element.value;
                }
            }
        }

        const businessAddressData = {
            cp_address_id: myFormData.businessAddress_address_id,
            street1: myFormData.businessAddress_street1,
            street2: myFormData.businessAddress_street2,
            street3: myFormData.businessAddress_street3,
            city: myFormData.businessAddress_city,
            state_code: myFormData.businessAddress_state_code,
            state_name: myFormData.businessAddress_state_name,
            post_code: myFormData.businessAddress_post_code,
            country_code: myFormData.businessAddress_country_code,
            country_name: myFormData.businessAddress_country_name,
            full_address: myFormData.businessAddress,
        };

        const formData = {
            email: this.state.businessEmail,
            phone: this.state.businessPhone,
            password: this.state.businessPassword,
            business_name: this.state.businessName,
            business_address: businessAddressData,
            user_type: 'enterprise'
        }

        this.callAction(formData);
    }

    callAction(formData) {
        this.setState({ isLoading: true });
        register(formData).then(res => {
            if (res) {
                this.setState({ messageColor: (res.status) ? 'success' : 'danger' });
                //this.setState({ showMessage: (!res.status) ? res.message : '' });
                this.setState({ showMessage: res.message });
                setTimeout(function () {
                    this.setState({ showMessage: '' });
                    this.setState({ messageColor: '' });
                    if (res.status) {
                        this.props.history.push('/login');
                    }
                }.bind(this), 5000);
                this.setState({ isLoading: false });
                this.setState({
                    normalEmail: '',
                    normalPhone: '',
                    normalPassword: '',
                    normalConfirmPassword: '',
                    businessEmail: '',
                    businessPhone: '',
                    businessAddress: '',
                    businessName: '',
                    businessPassword: '',
                    businessConfirmPassword: '',
                })
            }
        })
    }

    render() {
        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">Register</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={6}>

                                {this.state.showMessage !== "" ?
                                    <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                    : ''}

                                <Card className="border-0 rounded-0 register-form">
                                    <CardBody className="p-4">

                                        <Nav tabs className="justify-content-end border-0" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    href='#'
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => {
                                                        this.toggle('1');
                                                    }}
                                                >
                                                    Normal
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href='#'
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => {
                                                        this.toggle('2');
                                                    }}
                                                >
                                                    Enterprise
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent className="pt-4" activeTab={this.state.activeTab}>
                                            <TabPane tabId="1">

                                                <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.normailUserSubmit}
                                                >
                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            label="Email"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="normalEmail"
                                                            id="normalEmail"
                                                            margin="normal"
                                                            value={this.state.normalEmail}
                                                            validators={['required', 'isEmail']}
                                                            errorMessages={['this field is required', 'email is not valid']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="normalPhone"
                                                            name="normalPhone"
                                                            label="Phone"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.normalPhone}
                                                            validators={['required', 'matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$']}
                                                            errorMessages={['this field is required', 'Please enter valid phone.']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="normalPassword"
                                                            name="normalPassword"
                                                            label="Password"
                                                            type="password"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.normalPassword}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="normalConfirmPassword"
                                                            name="normalConfirmPassword"
                                                            label="Confirm Password"
                                                            type="password"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.normalConfirmPassword}
                                                            validators={['required', 'isPasswordMatch']}
                                                            errorMessages={['this field is required.', 'Confirm Password not matched with password.']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        {/* <button className="btn btn-block btn-success">Register</button> */}
                                                        <Button
                                                            color="success"
                                                            className="btn-block"
                                                            disabled={this.state.isLoading}
                                                        >
                                                            {this.state.isLoading ? 'Loading…' : 'Register'}
                                                        </Button>
                                                    </FormGroup>
                                                </ValidatorForm>

                                            </TabPane>
                                            <TabPane tabId="2">

                                                <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.businessUserSubmit}
                                                >
                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            label="Email"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="businessEmail"
                                                            id="businessEmail"
                                                            margin="normal"
                                                            value={this.state.businessEmail}
                                                            validators={['required', 'isEmail']}
                                                            errorMessages={['this field is required', 'email is not valid']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="businessPhone"
                                                            name="businessPhone"
                                                            label="Phone"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.businessPhone}
                                                            validators={['required', 'matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$']}
                                                            errorMessages={['this field is required', 'Please enter valid phone.']}
                                                        />
                                                    </FormGroup>


                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="businessName"
                                                            name="businessName"
                                                            label="Business Name"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.businessName}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                                <FormControl style={themeStyle.textField}>
                                                                    <SelectValidator
                                                                        label="Country"
                                                                        value={this.state.selectCountry ?  this.state.selectCountry : ''}
                                                                        onChange={this.onChange}
                                                                        validators={['required']}
                                                                        errormessages={['this field is required']}
                                                                        inputProps={{
                                                                            name: 'selectCountry',
                                                                            id: 'selectCountry',
                                                                        }}
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value="US">
                                                                            United States
                                                                        </MenuItem>
                                                                        <MenuItem value="CA">
                                                                            Canada
                                                                    </MenuItem>
                                                                    </SelectValidator>
                                                                </FormControl>
                                                            </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        {/* <TextValidator
                                                            id="businessAddress"
                                                            name="businessAddress"
                                                            label="Business Address"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.businessAddress}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        /> */}
                                                        <AutoCompleteAddress title='Business Address' name="businessAddress" id="businessAddress" country={this.state.selectCountry} />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="businessPassword"
                                                            name="businessPassword"
                                                            label="Password"
                                                            type="password"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.businessPassword}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            id="businessConfirmPassword"
                                                            name="businessConfirmPassword"
                                                            label="Confirm Password"
                                                            type="password"
                                                            style={themeStyle.textField}
                                                            margin="normal"
                                                            onChange={this.onChange}
                                                            value={this.state.businessConfirmPassword}
                                                            validators={['required', 'isBusinessPasswordMatch']}
                                                            errorMessages={['this field is required.', 'Confirm Password not matched with password.']}
                                                        />
                                                    </FormGroup>


                                                    <FormGroup>
                                                        {/* <button className="btn btn-block btn-success">Register</button> */}
                                                        <Button
                                                            color="success"
                                                            className="btn-block"
                                                            disabled={this.state.isLoading}
                                                        >
                                                            {this.state.isLoading ? 'Loading…' : 'Register'}
                                                        </Button>
                                                    </FormGroup>
                                                </ValidatorForm>

                                            </TabPane>
                                        </TabContent>

                                    </CardBody>
                                </Card>

                                <div className="mt-4">
                                    <p className="text-gray text-center">
                                        Already have an account? Please <Link to="./login">login here</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default Register;