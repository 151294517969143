import React from "react";
import { checkJWToken, getDecodedJWToken } from '../../functions/Auth';
import queryString from 'query-string';
import Moment from "react-moment";

import {
    Col,
    Container,
    Media,
    Row,
    Card,
    CardTitle,
    CardHeader,
    CardBody
} from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import Loader from "../components/general/Loader";


import { getOrderDetail } from "../../functions/APIRequest";

class OrderConfirm extends React.Component {

    constructor() {
        super();
        this.state = {
            showMessage: "",
            isLoading: false,
            showLoader: false,
            messageColor: "",
            errors: {},
            orderDetail: {}
        };
    }

    componentDidMount() {
        document.body.classList.add('orders-confirm');

        if (!checkJWToken()) {
            this.props.history.push('/login');
        }

        const urlParams = queryString.parse(this.props.location.search)
        if (!parseInt(urlParams.id)) {
            this.props.history.push('/home');
            return false;
        }

        this.setState({ showLoader: true });
        getOrderDetail(urlParams.id).then((res) => {
            const loginUser = getDecodedJWToken();
            if (parseInt(loginUser.userId) === parseInt(res.user_id)) {
                this.setState({ orderDetail: res, showLoader: false });
            } else {
                this.props.history.push('/home');
                return false;
            }
        })
    }

    componentWillUnmount() {
        document.body.classList.remove('orders-confirm');
    }

    getShippingMethodImage(method) {
        let imgResource = '';
        switch (method) {
            case 'fedex':
                imgResource = require('../../assets/images/assets/fedex.png');
                break;
            case 'canada_post':
                imgResource = require('../../assets/images/assets/canada-post.png');
                break;
            case 'purolator':
                imgResource = require('../../assets/images/assets/purolator.png');
                break;
            default:
                imgResource = require('../../assets/images/assets/fedex.png');
                break;
        }

        return imgResource;
    }

    getCardTypeImage(card_type) {
        var imageResource = require('../../assets/images/assets/stp_card_unknown.png')

        if (card_type === 'visa') {
            imageResource = require('../../assets/images/assets/stp_card_visa.png')
        }
        else if (card_type === 'mastercard') {
            imageResource = require('../../assets/images/assets/stp_card_mastercard.png')
        }
        else if (card_type === 'jcb') {
            imageResource = require('../../assets/images/assets/stp_card_jcb.png')
        }
        else if (card_type === 'discover') {
            imageResource = require('../../assets/images/assets/stp_card_discover.png')
        }
        else if (card_type === 'diners-club') {
            imageResource = require('../../assets/images/assets/stp_card_diners.png')
        }
        else if (card_type === 'american-express') {
            imageResource = require('../../assets/images/assets/stp_card_amex.png')
        }

        return imageResource
    }

    render() {

        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">Order Confirm</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row>

                            <Col md={12}>

                                {(this.state.showLoader) ?
                                    <Loader />
                                    :
                                    <div>
                                        <Media className="alert alert-success alert-dismissible fade show" role="alert">
                                            <Media left className="mr-4">
                                                <i className="la la-check-circle la-3x"></i>
                                            </Media>
                                            <Media body>
                                                <h5 className="alert-heading font-weight-bold">
                                                    Thank you for placing an Order.
                                                </h5>
                                                <p>Your order is under review.</p>
                                            </Media>
                                        </Media>

                                        <Card className="border-0 rounded-0 mb-4">
                                            <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                                <CardTitle tag="h5" className="mb-0">
                                                    Order Details
                                        </CardTitle>
                                            </CardHeader>

                                            <CardBody>

                                                <div className="orders-detail">
                                                    <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                        Sender INFORMATION
                                                    </h6>
                                                    
                                                    <hr />
                                                    {(typeof this.state.orderDetail.pick_up_address !== 'undefined') ?
                                                        <Row className="py-3">
                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-user text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">Name</span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {/* { this.state.orderDetail.pick_up_address.first_name + " " + this.state.orderDetail.pick_up_address.last_name } */}
                                                                            { this.state.orderDetail.pick_up_address.name }
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-map-marker text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Pick Up Address
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.pick_up_address.full_address}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            {/* <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-home text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Pick Up From
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.pick_up_address.pick_up_place}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-calendar text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Pick Up Time
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                        <Moment unix format="Do MMM, YYYY">
                                                                            {this.state.orderDetail.pick_up_address.pickup_slot_array.pickup_date_int}
                                                                        </Moment>
                                                                        &nbsp;
                                                                        {this.state.orderDetail.pick_up_address.pickup_slot_array.pickup_time}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col> */}
                                                        </Row>
                                                        : ''
                                                    }
                                                    <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                        Receiver INFORMATION
                                                    </h6>
                                                    <hr />
                                                    {(typeof this.state.orderDetail.drop_off_address !== 'undefined') ?
                                                        <Row className="py-3">
                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-user text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">Name</span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {/* {this.state.orderDetail.drop_off_address.first_name}&nbsp;
                                                                            {this.state.orderDetail.drop_off_address.last_name} */}
                                                                            {this.state.orderDetail.drop_off_address.name}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-phone text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Phone
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.drop_off_address.phone}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-map-marker text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Drop Off Address
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.drop_off_address.full_address}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                        </Row>
                                                        : ''}
                                                    <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                        Parcel Information
                                                    </h6>
                                                    <hr />

                                                    {(typeof this.state.orderDetail.parcel !== 'undefined') ?
                                                        <Row className="py-3">
                                                            <Col md={3} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-balance-scale text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Weight
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.parcel.weight} lb
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>
                                                            <Col md={3} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-cube text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Width
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.parcel.width} in
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>
                                                            <Col md={3} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-cube text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Height
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.parcel.height} in
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>
                                                            <Col md={3} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-cube text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Length
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.parcel.length} in
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>
                                                        </Row>
                                                        : ''
                                                    }
                                                    <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                        Shipping Information
                                                    </h6>

                                                    <hr />

                                                    <Row className="py-3">
                                                        <Col md={6} className="mb-3">
                                                            <Media className="orders-media">
                                                                <Media left className="orders-media-icon">
                                                                    <i className="la la-truck text-gray"></i>
                                                                </Media>
                                                                <Media body>
                                                                    <span className="font-weight-bold small text-gray text-uppercase">
                                                                        Service
                                                                    </span>
                                                                    <div className="service-company-logo">
                                                                        <img src={this.getShippingMethodImage(this.state.orderDetail.shipping_method)} className="img-fluid" alt="fedex" />
                                                                    </div>
                                                                    {(typeof this.state.orderDetail.rate_detail !== 'undefined') ?
                                                                        <p className="service-company mb-0">{this.state.orderDetail.rate_detail.service_type_name}</p>
                                                                        : ''
                                                                    }
                                                                </Media>
                                                            </Media>
                                                        </Col>

                                                        <Col md={6} className="mb-3">
                                                            <Media className="orders-media">
                                                                <Media left className="orders-media-icon">
                                                                    <i className="la la-money text-gray"></i>
                                                                </Media>
                                                                <Media body>
                                                                    <span className="font-weight-bold small text-gray text-uppercase">
                                                                        Price
                                                                    </span>
                                                                    <h6 className="font-weight-bold pt-1 small small">
                                                                        ${this.state.orderDetail.rate}
                                                                    </h6>
                                                                </Media>
                                                            </Media>
                                                        </Col>

                                                        <Col md={6} className="mb-3">
                                                            <Media className="orders-media">
                                                                <Media left className="orders-media-icon">
                                                                    <i className="la la-calendar text-gray"></i>
                                                                </Media>
                                                                <Media body>
                                                                    <span className="font-weight-bold small text-gray text-uppercase">
                                                                        Order On
                                                                    </span>
                                                                    <h6 className="font-weight-bold pt-1 small small">
                                                                        <Moment unix format="Do MMM, YYYY">
                                                                            {this.state.orderDetail.order_date_int}
                                                                        </Moment>
                                                                    </h6>
                                                                </Media>
                                                            </Media>
                                                        </Col>

                                                        <Col md={6} className="mb-3">
                                                            <Media className="orders-media">
                                                                <Media left className="orders-media-icon">
                                                                    <i className="la la-calendar text-gray"></i>
                                                                </Media>
                                                                {(this.state.orderDetail.delivered_date_int !== '') ?
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Delivered On
                                                                    </span>
                                                                        <h6 className="font-weight-bold pt-1 small small">
                                                                            <Moment unix format="Do MMM, YYYY">
                                                                                {this.state.orderDetail.delivered_date_int}
                                                                            </Moment>
                                                                        </h6>
                                                                    </Media>
                                                                    :
                                                                    <Media body>
                                                                        {(typeof this.state.orderDetail.rate_detail !== "undefined") ?
                                                                            <div>
                                                                                <span className="font-weight-bold small text-gray text-uppercase">
                                                                                    Expected Delivery Date
                                                                                </span>
                                                                                <h6 className="font-weight-bold pt-1 small small">
                                                                                    <Moment format="Do MMM, YYYY">
                                                                                        {this.state.orderDetail.rate_detail.service_delivery_date}
                                                                                    </Moment>
                                                                                </h6>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                    </Media>
                                                                }
                                                            </Media>
                                                        </Col>

                                                    </Row>
                                                    {(this.state.orderDetail.payment_status) ?
                                                        <div>
                                                            <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                Payment Information
                                                            </h6>

                                                            <hr />

                                                            <Row className="py-3">
                                                                <Col md={6} className="mb-3">
                                                                    <Media className="orders-media">
                                                                        <Media left className="orders-media-icon">
                                                                            <i className="la la-credit-card text-gray"></i>
                                                                        </Media>
                                                                        <Media body>
                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                Card Detail
                                                                            </span>
                                                                            <div className="card-company-logo d-flex align-items-center">
                                                                                <img src={this.getCardTypeImage(this.state.orderDetail.payment_method)} className="img-fluid" alt="fedex" />
                                                                                <h6 className="font-weight-bold pt-1 small mb-0">- {this.state.orderDetail.card_no}</h6>
                                                                            </div>
                                                                        </Media>
                                                                    </Media>
                                                                </Col>

                                                                <Col md={6} className="mb-3">
                                                                    <Media className="orders-media">
                                                                        <Media left className="orders-media-icon">
                                                                            <i className="la la-calendar text-gray"></i>
                                                                        </Media>
                                                                        <Media body>
                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                Date Paid
                                                                            </span>
                                                                            <h6 className="font-weight-bold pt-1 small small">
                                                                            <Moment unix format="Do MMM, YYYY">
                                                                            {this.state.orderDetail.payment_date_int}
                                                                            </Moment>
                                                                            </h6>
                                                                        </Media>
                                                                    </Media>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                        : ''
                                                    }
                                                </div>

                                            </CardBody>
                                        </Card>
                                    </div>
                                }
                            </Col>

                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default OrderConfirm;