import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.css";
import "assets/css/theme.css";
import "assets/css/fonts.css";
import "assets/css/line-awesome.min.css";
import "assets/css/layout.css";

// pages
import Home from "views/Home";
import Login from "views/Login";
import Register from "views/Register";
import ForgotPassword from "views/ForgotPassword";
import MyAccount from "views/users/MyAccount";
import MyOrders from "views/users/MyOrders";
import OrderDetail from "views/users/OrderDetail";
import ChangePassword from "views/users/ChangePassword";
import CreateOrder from "views/orderProcess/CreateOrder";
import GetRates from "views/orderProcess/GetRates";
import OrderPay from "views/orderProcess/OrderPay";
import OrderConfirm from "views/orderProcess/OrderConfirm";
import ScrollToTop from "./functions/ScrollToTop";
// others

import AboutUs from "views/pages/AboutUs";
import ContactUs from "views/pages/ContactUs";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import TermsConditions from "views/pages/TermsConditions";
import Blogs from "views/pages/Blogs";
import Claims from "views/pages/Claims";
import FAQs from "views/pages/FAQs";

ReactDOM.render(
  <BrowserRouter basename={'/'}>
    <ScrollToTop>
    <Switch>
      <Route path="/home" render={props => <Home {...props} />} />
        <Route path="/about-us"render={props => <AboutUs {...props} />}/>
        <Route path="/contact-us"render={props => <ContactUs {...props} />}/>
        <Route path="/privacy-policy"render={props => <PrivacyPolicy {...props} />}/>
        <Route path="/terms-conditions"render={props => <TermsConditions {...props} />}/>
        <Route path="/blogs"render={props => <Blogs {...props} />}/>
        <Route path="/faq"render={props => <FAQs {...props} />}/>
        
        <Route path="/login"render={props => <Login {...props} />}/>
        {/* <Route path="/register"render={props => <Register {...props} />}/> */}
        <Route path="/forgot-password"render={props => <ForgotPassword {...props} />}/>
        <Route path="/my-account" render={props => <MyAccount {...props} />}/>
        <Route path="/my-orders" render={props => <MyOrders {...props} />}/>
        <Route path="/order/detail/:orderId" render={props => <OrderDetail {...props} />}/>
        <Route path="/user/change-passowrd" render={props => <ChangePassword {...props} />}/>
        <Route path="/create-order" render={props => <CreateOrder {...props} />}/>
        <Route path="/rates" render={props => <GetRates {...props} />}/>
        <Route path="/order/pay" render={props => <OrderPay {...props} />}/>
        <Route path="/order/confirm" render={props => <OrderConfirm {...props} />}/>
      <Redirect to="/home" />
    </Switch>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);
