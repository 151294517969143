import React from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import themeStyle from "../components/general/ThemeStyle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { checkJWToken } from "../../functions/Auth";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Row,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import AlertMessages from "../components/general/AlertMessages";
import Loader from "../components/general/Loader";

import OrderProcessSidebar from "./OrderProcessSidebar";

import {
  // getState,
  getStateLists,
  getCountryLists,
  placeOrder,
} from "../../functions/APIRequest";
import { generateToken, makePayment } from "../../functions/StripeRequest";

var valid = require("card-validator");

class GetRates extends React.Component {
  constructor() {
    super();
    this.state = {
      showMessage: "",
      isLoading: false,
      showLoader: false,
      messageColor: "",
      rates: {},
      orderData: [],
      allStates: {},
      countryStates: [],
      userConsentChecked: false,
      errors: {},
      cardHolderName: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCVV: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingCity: "",
      billingZipCode: "",
      billingCountry: "",
      billingState: "",
      orderInfo: {},
      chkErrorClass: "",
      billingStateLists: [],

      senderName: "",
      senderEmail: "",
      senderPhone: "",
      senderAddressLine1: "",
      senderAddressLine2: "",
      senderCity: "",
      senderCountry: "",
      senderState: "",
      senderZipCode: "",
      senderStateList: [],

      receiverName: "",
      receiverEmail: "",
      receiverPhone: "",
      receiverAddressLine1: "",
      receiverAddressLine2: "",
      receiverCity: "",
      receiverCountry: "",
      receiverState: "",
      receiverZipCode: "",
      receiverStateLists: [],

      countryLists: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onReceiverCountryChange = this.onReceiverCountryChange.bind(this);
    this.onUserConsentChanged = this.onUserConsentChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    document.body.classList.add("orders-process-pay");

    if (!checkJWToken()) {
      this.props.history.push("/login");
    }

    if (typeof localStorage.orderInfo !== "undefined") {
      const orderInfo = JSON.parse(localStorage.orderInfo);
      const userInfo = JSON.parse(localStorage.user);

      this.setState({ orderInfo: orderInfo });

      getCountryLists().then((res) => {
        if (res.status) {
          const data = res.data;
          this.setState({ countryLists: data });
        }
      });

      // this.senderStateList = await this.getStateLists("CA");

      this.setState({ countryStates: [] });
      this.setState({ allStates: [] });

      // getState().then((res) => {
      //   this.setState({ allStates: res });
      //   const country = "CA";
      //   const selectedStates =
      //     this.state.allStates[country] !== "undefined"
      //       ? this.state.allStates[country]
      //       : [];

      //   this.setState({ countryStates: selectedStates });

      //   const orderData = orderInfo.info;
      //   this.setState({
      //     senderName:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.name
      //         : "",
      //     senderEmail:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.email
      //         : "",
      //     senderPhone:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.phone
      //         : "",

      //     senderCountry: orderData.originCountry,
      //     senderCity: orderData.originCity,
      //     senderZipCode: orderData.originPostCode,
      //     senderState: orderData.originState,

      //     receiverCountry: orderData.DestinationCountry,
      //     receiverCity: orderData.destinationCity,
      //     receiverState: orderData.destinationState,
      //     receiverZipCode: orderData.destinationPostCode,

      //     billingAddressLine1:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.address_line_1
      //         : "",
      //     billingAddressLine2:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.address_line_2
      //         : "",
      //     billingCity:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.city
      //         : "",
      //     billingZipCode:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.zip_code
      //         : "",
      //     billingCountry:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.country
      //         : "CA",
      //     billingState:
      //       typeof userInfo.business !== "undefined"
      //         ? userInfo.business.state
      //         : "",
      //   });
      // });

      const orderData = orderInfo.info;

      const billingCountry =
        typeof userInfo.business !== "undefined"
          ? userInfo.business.country
          : "CA";
      this.setState({
        senderName:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.name
            : "",
        senderEmail:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.email
            : "",
        senderPhone:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.phone
            : "",

        senderCountry: orderData.originCountry,
        senderCity: orderData.originCity,
        senderZipCode: orderData.originPostCode,
        senderStateList: await this.getStateLists(orderData.originCountry),
        senderState: orderData.originState,

        receiverCountry: orderData.DestinationCountry,
        receiverCity: orderData.destinationCity,
        receiverStateLists: await this.getStateLists(
          orderData.DestinationCountry
        ),
        receiverState: orderData.destinationState,
        receiverZipCode: orderData.destinationPostCode,

        billingAddressLine1:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.address_line_1
            : "",
        billingAddressLine2:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.address_line_2
            : "",
        billingCity:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.city
            : "",
        billingZipCode:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.zip_code
            : "",
        billingCountry: billingCountry,
        billingStateLists: await this.getStateLists(billingCountry),
        billingState:
          typeof userInfo.business !== "undefined"
            ? userInfo.business.state
            : "",
      });
    } else {
      this.props.history.push("/");
    }

    this.validate();
  }

  componentWillUnmount() {
    document.body.classList.remove("orders-process-pay");
  }

  async getStateLists(countryCode) {
    if (countryCode === "CA" || countryCode === "US") {
      return await getStateLists(countryCode).then((res) => {
        if (res.status) {
          const data = res.data;
          return data;
        }
      });
    } else {
      return [];
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.validate();
  }

  onUserConsentChanged(e) {
    this.setState({
      userConsentChecked: e.target.checked,
      chkErrorClass: e.target.checked ? "" : "Mui-error",
    });
  }

  onCountryChange(e) {
    let selectedStates = [];
    // if (e.target.value !== "") {
    //   selectedStates =
    //     this.state.allStates[e.target.value] !== "undefined"
    //       ? this.state.allStates[e.target.value]
    //       : [];
    // }
    this.setState({
      [e.target.name]: e.target.value,
      countryStates: selectedStates,
    });
  }

  async onReceiverCountryChange(e) {
    const countryCode = e.target.value;

    this.setState({
      [e.target.name]: e.target.value,
      receiverStateLists: await this.getStateLists(countryCode),
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.userConsentChecked) {
      this.setState({ chkErrorClass: "Mui-error" });
      return false;
    }

    const senderInfo = {
      name: this.state.senderName,
      email: this.state.senderEmail,
      phone: this.state.senderPhone,
      address1: this.state.senderAddressLine1,
      address2: this.state.senderAddressLine2,
      country: this.state.senderCountry,
      state: this.state.senderState,
      city: this.state.senderCity,
      zipcode: this.state.senderZipCode,
    };

    const receiverInfo = {
      name: this.state.receiverName,
      email: this.state.receiverEmail,
      phone: this.state.receiverPhone,
      address1: this.state.receiverAddressLine1,
      address2: this.state.receiverAddressLine1,
      country: this.state.receiverCountry,
      state: this.state.receiverState,
      city: this.state.receiverCity,
      zipcode: this.state.receiverZipCode,
    };

    if (typeof this.state.orderInfo !== "undefined") {
      this.state.orderInfo.info["senderInfo"] = senderInfo;
      this.state.orderInfo.info["receiverInfo"] = receiverInfo;
    }

    var cardDetails = {
      "card[number]": this.state.cardNumber,
      "card[exp_month]": this.state.cardExpiryMonth,
      "card[exp_year]": this.state.cardExpiryYear,
      "card[cvc]": this.state.cardCVV,
      "card[name]": this.state.cardHolderName,
      "card[address_line1]": this.state.billingAddressLine1,
      "card[address_line2]": this.state.billingAddressLine2,
      "card[address_city]": this.state.billingCity,
      "card[address_state]": this.state.billingState,
      "card[address_zip]": this.state.billingZipCode,
      "card[address_country]": this.state.billingCountry,
    };

    let responseMessage = "";
    let responseMessageType = "";
    let order_id = 0;

    this.setState({ showLoader: true });
    generateToken(cardDetails)
      .then((res) => {
        if (res.status === "S") {
          var paymentDetails = {
            amount: Math.ceil(this.state.orderInfo.rateData.rate * 100),
            currency: "cad",
            source: res.data.id,
            description:
              "$" +
              this.state.orderInfo.rateData.rate +
              " Charge for Shipeazy Order of " +
              this.state.orderInfo.rateData.api +
              " with service type " +
              this.state.orderInfo.rateData.service_type_name,
          };

          makePayment(paymentDetails).then((res) => {
            if (res.status === "S" && typeof res.data !== "undefined") {
              placeOrder(
                this.state.orderInfo.info,
                this.state.orderInfo.rateData,
                res.data
              ).then((orderRes) => {
                if (orderRes.status) {
                  order_id = orderRes.data.order_id;

                  localStorage.removeItem("orderInfo");

                  this.props.history.push("/order/confirm?id=" + order_id);

                  return false;
                } else {
                  responseMessage = orderRes.message;
                  responseMessageType = "danger";
                }
              });
            } else {
              responseMessage = res.message;
              responseMessageType = "danger";
            }
          });
        } else {
          responseMessage = res.message;
          responseMessageType = "danger";
          this.setState({
            showMessage: responseMessage,
            messageColor: responseMessageType,
          });
          this.setState({ showLoader: false });
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      showMessage: responseMessage,
      messageColor: responseMessageType,
    });

    setTimeout(
      function () {
        this.setState({ showMessage: "", messageColor: "", email: "" });
        this.setState({ showLoader: false });
      }.bind(this),
      5000
    );
  }

  getShippingMethodImage(method) {
    let imgResource = "";
    switch (method) {
      case "fedex":
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
      case "canada_post":
        imgResource = require("../../assets/images/assets/canada-post.png");
        break;
      case "purolator":
        imgResource = require("../../assets/images/assets/purolator.png");
        break;
      default:
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
    }

    return imgResource;
  }

  getCardTypeIamge() {
    var numberValidation = valid.number(this.state.cardNumber);
    var card_type =
      numberValidation.card === null ? "" : numberValidation.card.type;
    var imageResource = require("../../assets/images/assets/stp_card_unknown.png");

    if (card_type === "visa") {
      imageResource = require("../../assets/images/assets/stp_card_visa.png");
    } else if (card_type === "mastercard") {
      imageResource = require("../../assets/images/assets/stp_card_mastercard.png");
    } else if (card_type === "jcb") {
      imageResource = require("../../assets/images/assets/stp_card_jcb.png");
    } else if (card_type === "discover") {
      imageResource = require("../../assets/images/assets/stp_card_discover.png");
    } else if (card_type === "diners-club") {
      imageResource = require("../../assets/images/assets/stp_card_diners.png");
    } else if (card_type === "american-express") {
      imageResource = require("../../assets/images/assets/stp_card_amex.png");
    }

    return imageResource;
  }

  validate() {
    ValidatorForm.addValidationRule("isEmpty", (value) => {
      if (value.trim() === "") {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("validCreditCardNumber", (value) => {
      var numberValidation = valid.number(value);
      if (!numberValidation.isValid) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("validExpirationMonth", (value) => {
      // var numberValidation = valid.expirationMonth(value);
      // if (!numberValidation.isValid) {
      //     return false;
      // }
      return true;
    });

    ValidatorForm.addValidationRule("validExpirationYear", (value) => {
      // var numberValidation = valid.expirationYear(value);
      // if (!numberValidation.isValid) {
      //     return false;
      // }
      return true;
    });

    ValidatorForm.addValidationRule("validExpirationDate", (value) => {
      var expDate = {
        month: this.state.cardExpiryMonth.toString(),
        year: this.state.cardExpiryYear,
      };
      var numberValidation = valid.expirationDate(expDate);

      if (!numberValidation.isValid) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("validCardCVV", (value) => {
      var numberValidation = valid.cvv(value);

      if (!numberValidation.isValid) {
        return false;
      }
      return true;
    });
  }

  render() {
    let expiryMonths = [];
    for (let i = 1; i <= 12; i++) {
      if (i >= 1 && i <= 9) {
        expiryMonths.push(
          <MenuItem key={i} value={`0${i}`}>
            0{i}
          </MenuItem>
        );
      } else {
        expiryMonths.push(
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        );
      }
    }

    return (
      <>
        <Header />

        <div className="pageheader-wrapper py-5">
          <Container>
            <h2 className="banner-title mb-md-3 text-white">Order Payment</h2>
          </Container>
        </div>

        {this.state.showLoader ? <Loader /> : ""}
        <section className="content-wrapper py-5">
          <Container>
            {this.state.showMessage !== "" ? (
              <AlertMessages
                color={this.state.messageColor}
                message={this.state.showMessage}
              />
            ) : (
              ""
            )}

            <Row>
              <OrderProcessSidebar
                orderData={
                  typeof this.state.orderInfo.info !== "undefined"
                    ? this.state.orderInfo.info
                    : {}
                }
                rateData={
                  typeof this.state.orderInfo.rateData !== "undefined"
                    ? this.state.orderInfo.rateData
                    : {}
                }
              />

              <Col md={8}>
                <Card className="border-0 rounded-0 mb-4 h-100">
                  <ValidatorForm
                    ref="form"
                    instantValidate={true}
                    onSubmit={this.handleSubmit}
                    // onError={errors => console.log(errors)}
                  >
                    <CardHeader className="bg-transparent d-md-flex justify-content-between">
                      <CardTitle tag="h5" className="mb-0">
                        Sender Info
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Name"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="senderName"
                                id="senderName"
                                value={this.state.senderName}
                                validators={["required", "isEmpty"]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Email"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="senderEmail"
                                id="senderEmail"
                                value={this.state.senderEmail}
                                validators={["required", "isEmpty", "isEmail"]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                  "email is not valid",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Phone"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="senderPhone"
                                id="senderPhone"
                                inputProps={{
                                  "data-valid": "numeric",
                                  maxLength: "10",
                                }}
                                value={this.state.senderPhone}
                                validators={[
                                  "required",
                                  "isEmpty",
                                  "matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$",
                                ]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                  "Please enter valid phone.",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Address Line 1"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="senderAddressLine1"
                              id="senderAddressLine1"
                              value={this.state.senderAddressLine1}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Address Line 2"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="senderAddressLine2"
                              id="senderAddressLine2"
                              value={this.state.senderAddressLine2}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <FormControl style={themeStyle.textField}>
                              {/* <InputLabel htmlFor="billingCountry">Country</InputLabel> */}
                              <SelectValidator
                                label="Country"
                                value={this.state.senderCountry}
                                onChange={this.onCountryChange}
                                validators={["required"]}
                                errormessages={["this field is required"]}
                                inputProps={{
                                  name: "senderCountry",
                                  id: "senderCountry",
                                  disabled: true,
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {this.state.countryLists
                                  ? this.state.countryLists.map(
                                      (option, _id) => (
                                        <MenuItem
                                          key={_id}
                                          value={option.country_code}
                                        >
                                          {option.country_name}
                                        </MenuItem>
                                      )
                                    )
                                  : ""}
                              </SelectValidator>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <FormControl style={themeStyle.textField}>
                              {/* <InputLabel htmlFor="billingState">State</InputLabel> */}
                              <SelectValidator
                                label="State"
                                value={this.state.senderState}
                                onChange={this.onChange}
                                validators={["required"]}
                                errormessages={["this field is required"]}
                                inputProps={{
                                  name: "senderState",
                                  id: "senderState",
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {this.state.senderStateList.length > 0
                                  ? this.state.senderStateList.map(
                                      (option, _id) => (
                                        <MenuItem
                                          key={_id}
                                          value={option.state_code}
                                        >
                                          {option.state}
                                        </MenuItem>
                                      )
                                    )
                                  : ""}
                              </SelectValidator>
                            </FormControl>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="City"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="senderCity"
                              id="senderCity"
                              value={this.state.senderCity}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Zip Code"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="senderZipCode"
                              id="senderZipCode"
                              value={this.state.senderZipCode}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>

                    <CardHeader className="bg-transparent d-md-flex justify-content-between">
                      <CardTitle tag="h5" className="mb-0">
                        Receiver Info
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Name"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="receiverName"
                                id="receiverName"
                                value={this.state.receiverName}
                                validators={["required", "isEmpty"]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Email"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="receiverEmail"
                                id="receiverEmail"
                                value={this.state.receiverEmail}
                                // validators={["required", "isEmpty", "isEmail"]}
                                validators={["isEmail"]}
                                errorMessages={[
                                  // "this field is required",
                                  // "this field is required",
                                  "email is not valid",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="company-listing-wrap">
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Phone"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="receiverPhone"
                                id="receiverPhone"
                                inputProps={{
                                  "data-valid": "numeric",
                                  maxLength: "10",
                                }}
                                value={this.state.receiverPhone}
                                validators={[
                                  "required",
                                  "isEmpty",
                                  "matchRegexp:^([0-9]{3})[0-9]{3}[0-9]{4}$",
                                ]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                  "Please enter valid phone.",
                                ]}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Address Line 1"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="receiverAddressLine1"
                              id="receiverAddressLine1"
                              value={this.state.receiverAddressLine1}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Address Line 2"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="receiverAddressLine2"
                              id="receiverAddressLine2"
                              value={this.state.receiverAddressLine2}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <FormControl style={themeStyle.textField}>
                              {/* <InputLabel htmlFor="billingCountry">Country</InputLabel> */}
                              <SelectValidator
                                label="Country"
                                value={this.state.receiverCountry}
                                onChange={this.onReceiverCountryChange}
                                validators={["required"]}
                                errormessages={["this field is required"]}
                                inputProps={{
                                  name: "receiverCountry",
                                  id: "receiverCountry",
                                  // disabled: true,
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {this.state.countryLists
                                  ? this.state.countryLists.map(
                                      (option, _id) => (
                                        <MenuItem
                                          key={_id}
                                          value={option.country_code}
                                        >
                                          {option.country_name}
                                        </MenuItem>
                                      )
                                    )
                                  : ""}
                              </SelectValidator>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <FormControl style={themeStyle.textField}>
                              {this.state.receiverStateLists.length > 0 ? (
                                <SelectValidator
                                  label="State"
                                  value={this.state.receiverState}
                                  onChange={this.onChange}
                                  validators={["required"]}
                                  errormessages={["this field is required"]}
                                  inputProps={{
                                    name: "receiverState",
                                    id: "receiverState",
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {this.state.receiverStateLists.length > 0
                                    ? this.state.receiverStateLists.map(
                                        (option, _id) => (
                                          <MenuItem
                                            key={_id}
                                            value={option.state_code}
                                          >
                                            {option.state}
                                          </MenuItem>
                                        )
                                      )
                                    : ""}
                                </SelectValidator>
                              ) : (
                                <FormGroup className="fancy-form-group mb-1">
                                  <TextValidator
                                    label="Destination State"
                                    onChange={this.onChange}
                                    style={themeStyle.textField}
                                    name="receiverState"
                                    id="receiverState"
                                    value={this.state.receiverState}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                  />
                                </FormGroup>
                              )}
                            </FormControl>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="City"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="receiverCity"
                              id="receiverCity"
                              value={this.state.receiverCity}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="fancy-form-group">
                            <TextValidator
                              label="Zip Code"
                              onChange={this.onChange}
                              style={themeStyle.textField}
                              name="receiverZipCode"
                              id="receiverZipCode"
                              value={this.state.receiverZipCode}
                              validators={["required", "isEmpty"]}
                              errorMessages={[
                                "this field is required",
                                "this field is required",
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>

                    <CardHeader className="bg-transparent d-md-flex justify-content-between">
                      <CardTitle tag="h5" className="mb-0">
                        Card Details
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div className="company-listing-wrap">
                        <FormGroup className="fancy-form-group">
                          <TextValidator
                            label="Card Holder Name"
                            onChange={this.onChange}
                            style={themeStyle.textField}
                            name="cardHolderName"
                            id="cardHolderName"
                            value={this.state.cardHolderName}
                            validators={["required", "isEmpty"]}
                            errorMessages={[
                              "this field is required",
                              "this field is required",
                            ]}
                          />
                        </FormGroup>

                        <FormGroup className="fancy-form-group">
                          <TextValidator
                            label="Card Number"
                            onChange={this.onChange}
                            style={themeStyle.textField}
                            name="cardNumber"
                            id="cardNumber"
                            value={this.state.cardNumber}
                            inputProps={{
                              maxLength: "16",
                            }}
                            validators={["required", "validCreditCardNumber"]}
                            errorMessages={[
                              "this field is required",
                              "Please enter valid card number.",
                            ]}
                          />
                          <div className="card-image">
                            <img src={this.getCardTypeIamge()} alt="Visa" />
                          </div>
                        </FormGroup>

                        <Row>
                          <Col md={4}>
                            <FormGroup className="fancy-form-group">
                              <FormControl style={themeStyle.textField}>
                                {/* <InputLabel htmlFor="cardExpiryMonth">Expiry Month (MM)</InputLabel> */}
                                <SelectValidator
                                  value={this.state.cardExpiryMonth}
                                  label="Expiry Month (MM)"
                                  onChange={this.onChange}
                                  validators={[
                                    "required",
                                    "validExpirationDate",
                                  ]}
                                  errormessages={[
                                    "this field is required",
                                    "Please select valid expiration date.",
                                  ]}
                                  inputProps={{
                                    name: "cardExpiryMonth",
                                    id: "cardExpiryMonth",
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {expiryMonths}
                                </SelectValidator>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Expiry Year(YYYY)"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="cardExpiryYear"
                                id="cardExpiryYear"
                                ref={(input) =>
                                  (this.inputCardExpiryYear = input)
                                }
                                inputProps={{
                                  maxLength: "4",
                                }}
                                value={this.state.cardExpiryYear}
                                validators={["required", "validExpirationDate"]}
                                errorMessages={[
                                  "this field is required",
                                  "Please select valid expiration date.",
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="CVV"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="cardCVV"
                                id="cardCVV"
                                inputProps={{
                                  maxLength: "4",
                                }}
                                value={this.state.cardCVV}
                                validators={["required", "validCardCVV"]}
                                errorMessages={[
                                  "this field is required",
                                  "Invalid CVV",
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>

                    <CardHeader className="bg-transparent d-md-flex justify-content-between">
                      <CardTitle tag="h5" className="mb-0">
                        Billing Address Details
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div className="company-listing-wrap">
                        <FormGroup className="fancy-form-group">
                          <TextValidator
                            label="Address Line 1"
                            onChange={this.onChange}
                            style={themeStyle.textField}
                            name="billingAddressLine1"
                            id="billingAddressLine1"
                            value={this.state.billingAddressLine1}
                            validators={["required", "isEmpty"]}
                            errorMessages={[
                              "this field is required",
                              "this field is required",
                            ]}
                          />
                        </FormGroup>

                        <FormGroup className="fancy-form-group">
                          <TextValidator
                            label="Address Line 2"
                            onChange={this.onChange}
                            style={themeStyle.textField}
                            name="billingAddressLine2"
                            id="billingAddressLine2"
                            value={this.state.billingAddressLine2}
                          />
                        </FormGroup>

                        <Row>
                          <Col md={6}>
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="City"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="billingCity"
                                id="billingCity"
                                value={this.state.billingCity}
                                validators={["required", "isEmpty"]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="fancy-form-group">
                              <TextValidator
                                label="Zip Code"
                                onChange={this.onChange}
                                style={themeStyle.textField}
                                name="billingZipCode"
                                id="billingZipCode"
                                value={this.state.billingZipCode}
                                validators={["required", "isEmpty"]}
                                errorMessages={[
                                  "this field is required",
                                  "this field is required",
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <FormGroup className="fancy-form-group">
                              <FormControl style={themeStyle.textField}>
                                {/* <InputLabel htmlFor="billingCountry">Country</InputLabel> */}
                                <SelectValidator
                                  label="Country"
                                  value={this.state.billingCountry}
                                  onChange={this.onCountryChange}
                                  validators={["required"]}
                                  errormessages={["this field is required"]}
                                  inputProps={{
                                    name: "billingCountry",
                                    id: "billingCountry",
                                    disabled: true,
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {this.state.countryLists
                                    ? this.state.countryLists.map(
                                        (option, _id) => (
                                          <MenuItem
                                            key={_id}
                                            value={option.country_code}
                                          >
                                            {option.country_name}
                                          </MenuItem>
                                        )
                                      )
                                    : ""}
                                </SelectValidator>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="fancy-form-group">
                              <FormControl style={themeStyle.textField}>
                                {/* <InputLabel htmlFor="billingState">State</InputLabel> */}
                                <SelectValidator
                                  label="State"
                                  value={this.state.billingState}
                                  onChange={this.onChange}
                                  validators={["required"]}
                                  errormessages={["this field is required"]}
                                  inputProps={{
                                    name: "billingState",
                                    id: "billingState",
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {this.state.billingStateLists.length > 0
                                    ? this.state.billingStateLists.map(
                                        (option, _id) => (
                                          <MenuItem
                                            key={_id}
                                            value={option.state_code}
                                          >
                                            {option.state}
                                          </MenuItem>
                                        )
                                      )
                                    : ""}
                                </SelectValidator>
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>

                    <Col md={12}>
                      <FormGroup className={`custom-control custom-checkbox`}>
                        <input
                          type="checkbox"
                          name="userConsent"
                          id="userConsent"
                          className="custom-control-input"
                          onChange={this.onUserConsentChanged}
                          checked={this.state.userConsentChecked}
                        />

                        <InputLabel
                          className={`custom-control-label ${this.state.chkErrorClass}`}
                          htmlFor="userConsent"
                        >
                          Click here to indicate that you are agree to the terms
                          presented in the &nbsp;
                          <a href="/terms-conditions" target="_blank">
                            Terms and Conditions
                          </a>
                          &nbsp; agreement
                        </InputLabel>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="pt-3">
                      <Button
                        // type="button"
                        type="submit"
                        color="success"
                        className="btn-block"
                        // disabled={true}
                        // disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? "Loading…" : "Place Order"}
                      </Button>
                    </Col>
                  </ValidatorForm>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </>
    );
  }
}

export default GetRates;
