import React from "react";
import Moment from "react-moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { getState } from "../../functions/APIRequest";

class OrderProcessSidebar extends React.Component {
  constructor() {
    super();

    this.state = {
      originStateName: "",
      destinationStateName: "",
    };
  }

  componentDidMount() {
    // await this.getStateName();
  }

  getStateName(type) {
    if (
      type === "sender" &&
      typeof this.props.orderData.originStateInfo !== "undefined"
    ) {
      return this.props.orderData.originStateInfo.state;
    } else if (
      type === "receiver" &&
      typeof this.props.orderData.destinationStateInfo !== "undefined"
    ) {
      return this.props.orderData.destinationStateInfo.state;
    }
    // getState().then((res) => {
    // const country = 'CA';
    //   const originCountryInfo = this.props.orderData.originCountryInfo;
    // console.log(await this.props.orderData);
    //   const selectedOriginStates =
    //     res[originCountry] !== "undefined" ? res[originCountry] : [];
    //   selectedOriginStates.map((single) => {
    //     if (single.abbreviation === this.props.orderData.originState) {
    //       this.setState({ originStateName: single.name });
    //     } else {
    //       this.setState({
    //         originStateName: this.props.orderData.originState,
    //       });
    //     }
    //   });
    //   const destinationCountry = this.props.orderData.DestinationCountry;
    //   const selectedOriginStates =
    //     res[originCountry] !== "undefined" ? res[originCountry] : [];
    //   selectedOriginStates.map((single) => {
    //     if (single.abbreviation === this.props.orderData.originState) {
    //       this.setState({ originStateName: single.name });
    //     } else {
    //       this.setState({
    //         originStateName: this.props.orderData.originState,
    //       });
    //     }
    //   if (
    //     single.abbreviation === this.props.orderData.destinationState
    //   ) {
    //     this.setState({ destinationStateName: single.name });
    //   }
    //   });
    // });
  }

  getCountryName(type) {
    if (
      type === "sender" &&
      typeof this.props.orderData.originCountryInfo !== "undefined"
    ) {
      return this.props.orderData.originCountryInfo.country_name;
    } else if (
      type === "receiver" &&
      typeof this.props.orderData.destinationCountryInfo !== "undefined"
    ) {
      return this.props.orderData.destinationCountryInfo.country_name;
    }
  }

  getShippingMethodImage(method) {
    let imgResource = "";
    switch (method) {
      case "fedex":
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
      case "canada_post":
        imgResource = require("../../assets/images/assets/canada-post.png");
        break;
      case "purolator":
        imgResource = require("../../assets/images/assets/purolator.png");
        break;
      default:
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
    }

    return imgResource;
  }

  getDateFormat(fullDate) {
    if (typeof fullDate !== "undefined") {
      const dateParts = fullDate.split("##");
      const date = dateParts[0];
      const timeSlot = dateParts[1];

      return (
        <>
          <Moment format="MM/DD/YYYY">{date}</Moment> {timeSlot}
        </>
      );
    }
  }

  render() {
    return (
      <>
        <Col md={4} tag="aside" className="sidebar">
          <Card className="border-0 rounded-0 h-100">
            <CardHeader className="bg-transparent d-md-flex justify-content-between">
              <CardTitle tag="h6" className="mb-0">
                Order Summery
              </CardTitle>
            </CardHeader>
            <CardBody className="order-summery-card pt-0">
              <ListGroup flush className="order-summery-list">
                <ListGroupItem className="px-0">
                  <h6 className="font-weight-bold small text-gray-dark text-uppercase">
                    Sender Detail
                  </h6>

                  <div className="row">
                    <div className="col-6">
                      <span className="text-cap">Country :</span>
                      <p>{this.getCountryName("sender")}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">Post Code :</span>
                      <p>{this.props.orderData.originPostCode}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">State :</span>
                      <p>{this.getStateName("sender")}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">City :</span>
                      <p>{this.props.orderData.originCity}</p>
                    </div>
                  </div>

                  {/* <span className="text-cap">Name :</span>
                                    <p>{this.props.orderData.senderFirstName} {this.props.orderData.senderLastName}</p> */}
                  {/* <span className="text-cap">Phone :</span>
                                    <p>{this.props.orderData.senderPhoneNumber}</p> */}
                  {/* {(this.props.orderData.senderEmail !== "") ?
                                        <span>
                                            <span className="text-cap">Email :</span>
                                            <p>{this.props.orderData.senderEmail}</p>
                                        </span>
                                        : ''} */}

                  {/* <p><span className="text-cap">Pickup Address  :</span> <br />
                                        <span>{this.props.orderData.pickUpAddress}</span></p>

                                    <span className="text-cap">Pick Up From :</span>
                                    <p>{this.props.orderData.senderPickUpFrom}</p>

                                    <span className="text-cap">Pick Up Time Slot :</span>
                                    <p>
                                        {this.getDateFormat(this.props.orderData.senderPickUpTimeSlot)}
                                    </p> */}
                </ListGroupItem>
                <ListGroupItem className="px-0">
                  <h6 className="font-weight-bold small text-gray-dark  text-uppercase">
                    Receiver Detail
                  </h6>

                  <div className="row">
                    <div className="col-6">
                      <span className="text-cap">Country :</span>
                      <p>{this.getCountryName("receiver")}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">Post Code :</span>
                      <p>{this.props.orderData.destinationPostCode}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">State :</span>
                      <p>{this.getStateName("receiver")}</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">City :</span>
                      <p>{this.props.orderData.destinationCity}</p>
                    </div>
                  </div>

                  {/* <span className="text-cap">Name :</span>
                                    <p>{this.props.orderData.receiverFirstName} {this.props.orderData.receiverLastName}</p>
                                    <span className="text-cap">Phone :</span>
                                    <p>{this.props.orderData.receiverPhone}</p>
                                    <p><span className="text-cap">Delivery Address  :</span><br />
                                        <span>{this.props.orderData.dropOffAddress}</span></p> */}
                </ListGroupItem>
                <ListGroupItem className="px-0">
                  <h6 className="font-weight-bold small text-gray-dark text-uppercase">
                    Parcel Information
                  </h6>
                  <div className="row">
                    <div className="col-6">
                      <span className="text-cap">Weight :</span>
                      <p>{this.props.orderData.parcelWeight}lb</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">Width :</span>
                      <p>{this.props.orderData.parcelWidth}in</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">Height :</span>
                      <p>{this.props.orderData.parcelHeight}in</p>
                    </div>
                    <div className="col-6">
                      <span className="text-cap">Length :</span>
                      <p>{this.props.orderData.parcelLength}in</p>
                    </div>
                  </div>
                </ListGroupItem>
                {this.props.rateData ? (
                  <ListGroupItem className="px-0">
                    <h6 className="font-weight-bold small text-gray-dark text-uppercase">
                      Carrier Information
                    </h6>
                    <div className="row">
                      <div className="col-6">
                        <span className="text-cap">Carrier :</span>
                        <div className="service-company-logo">
                          <img
                            src={this.getShippingMethodImage(
                              this.props.rateData.api
                            )}
                            className="img-fluid"
                            alt={this.props.rateData.api}
                          />
                        </div>
                        <p className="service-company mb-0">
                          {this.props.rateData.service_type_name}
                        </p>
                      </div>
                      <div className="col-6">
                        <span className="text-cap">Price :</span>
                        <p>${this.props.rateData.rate}</p>
                      </div>
                    </div>
                  </ListGroupItem>
                ) : (
                  ""
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default OrderProcessSidebar;
