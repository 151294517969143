import React from "react";
import { Container } from "reactstrap";

// import logo from "../../../assets/images/logo/etapali-logo.svg";
import logo from "../../../assets/images/logo/Shipeaz_Logo.png";

function ComingSoon() {
  const responseiveParams = {
    0: { items: 2 },
    600: { items: 4 },
    1000: { items: 5 },
  };

  return (
    <>
      <section className="coming-soon-section">
        <div className="comingsoon-wrapper d-flex justify-content-center align-items-center flex-column">
          <div className="brand-logo d-flex justify-content-center align-items-center">
            <img src={logo} alt="eTapali" className="img-fluid" />
          </div>
          <h1 className="text-white text-uppercase">Page Coming Soon</h1>
        </div>
      </section>
    </>
  );
}

export default ComingSoon;
