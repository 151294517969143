import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { getAddress, getAddressDetail } from "../../../functions/APIRequest";
import {
  Input
} from "reactstrap";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem key={suggestion.key} selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 600 : 400 }}>
            {part.text}
          </span>
        ))}
        <br />
        <span>
          <small>{suggestion.description}</small>
        </span>
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, countryCode) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? [] :
    getAddress(inputValue, countryCode).then(res => {
      if (res) {
        return Promise.all(res);
      }
    });
}


const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(0),
    left: 0,
    right: 0,
    maxHeight: 320,
    overflow: 'hidden',
    overflowY: 'auto'
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));

export default function AutoCompleteAddress(props) {
  
  var initialSuggestions = [];
  
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.full_address : '',
    street1: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.street1 : '',
    street2: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.street2 : '',
    street3: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.street3 : '',
    city: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.city : '',
    state_code: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.state_code : '',
    state_name: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.state_name : '',
    post_code: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.postcode : '',
    country_code: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.country_code : '',
    country_name: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.country_name : '',
    address_id: (typeof props.selectedAddress !== 'undefined') ? props.selectedAddress.address_id : '',
  });

  const [stateSuggestions, setSuggestions] = React.useState(initialSuggestions);

  const handleSuggestionsFetchRequested = ({ value }) => {
    const countryCode = (typeof props.country !== 'undefined') ? props.country : 'CA';
    getSuggestions(value, countryCode).then(function (results) {
      setSuggestions(results);
    })
    //setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    if (newValue.trim() !== '') {
      setState({
        ...state,
        [name]: newValue,
      });
    }
  };

  function getSuggestionValue(suggestion) {
    //const result = await getAddressDetail(suggestion.key);
    getAddressDetail(suggestion.key).then(res => {
      if (res) {
        setState({
          ...state,
          'single': res.full_address,
          'street1': res.line1,
          'street2': res.line2,
          'street3': res.line3,
          'city': res.city,
          'state_code': res.province_code,
          'state_name': res.province_name,
          'post_code': res.postal_code,
          'country_code': res.country_iso2,
          'country_name': res.country_name,
          'address_id': res.address_id
        });
      }
    });
    return '';
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: props.id,
          name: props.name,
          label: props.title,
          placeholder: '',
          value: state.single,
          multiline: true,
          onChange: handleChange('single'),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />

      <Input type="hidden" name={`${props.name}_street1`} id={`${props.name}_street1`} value={state.street1} />
      <Input type="hidden" name={`${props.name}_street2`} id={`${props.name}_street2`} value={state.street2} />
      <Input type="hidden" name={`${props.name}_street3`} id={`${props.name}_street3`} value={state.street3} />
      <Input type="hidden" name={`${props.name}_city`} id={`${props.name}_city`} value={state.city} />
      <Input type="hidden" name={`${props.name}_state_code`} id={`${props.name}_state_code`} value={state.state_code} />
      <Input type="hidden" name={`${props.name}_state_name`} id={`${props.name}_state_name`} value={state.state_name} />
      <Input type="hidden" name={`${props.name}_post_code`} id={`${props.name}_post_code`} value={state.post_code} />
      <Input type="hidden" name={`${props.name}_country_code`} id={`${props.name}_country_code`} value={state.country_code} />
      <Input type="hidden" name={`${props.name}_country_name`} id={`${props.name}_country_name`} value={state.country_name} />      
      <Input type="hidden" name={`${props.name}_address_id`} id={`${props.name}_address_id`} value={state.address_id} />

    </div>
  );
}