import React from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Media, ListGroup, ListGroupItem
} from "reactstrap";

import { getDecodedJWToken } from '../../functions/Auth';

class UserSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: '',
            isLoading: false,
            messageColor: '',
            fullName: '',
            email: '',
            profilePhoto: '',
        }
    }

    componentDidMount(){
        var userData = getDecodedJWToken();
        var firstName = (userData.first_name) ? userData.first_name : '';
        var lastName = (userData.last_name) ? userData.last_name : '';
        var business_name = (userData.business_name) ? userData.business_name : '';
        this.setState({
            fullName: (userData.user_type === '2') ? business_name : firstName+' '+lastName,
            email: userData.email,
            profilePhoto: userData.profile_photo_url
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.updatedUserData != this.props.updatedUserData){
            var userData = this.props.updatedUserData;
            var firstName = (userData.first_name) ? userData.first_name : '';
            var lastName = (userData.last_name) ? userData.last_name : '';
            var business_name = (userData.business_name) ? userData.business_name : '';
            this.setState({
                fullName: (userData.user_type === '2') ? business_name : firstName+' '+lastName,
                email: userData.email,
                profilePhoto: userData.profile_photo_url
            });            
        }
    }

    render() {
        return (
            <>
                <aside className="col-md-4 sidebar">
                    <div className="sidebar-widgets profile-widgets">
                        <Card className="border-0 rounded-0 mb-4">
                            <CardBody className="p-4">
                                <Media className="align-items-center">
                                    <Media left>
                                        <div className="profile-image mr-4"><img
                                            src={this.state.profilePhoto} className="img-fluid"
                                            alt="author" /></div>
                                    </Media>
                                    <Media body>
                                        <div className="profile-name">
                                            <p className="profile-name-text text-primary mb-0">{this.state.fullName}</p>

                                            <p className="designation small">{this.state.email}</p>
                                        </div>
                                    </Media>
                                </Media>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="sidebar-widgets sidebar-menu">
                        <Card className="border-0 rounded-0 mb-4">
                            <CardBody className="p-0">
                                <ListGroup className="list-group-flush">
                                    <ListGroupItem className={this.props.location.pathname === '/my-account' ? 'active' : ''}>
                                        <Link to="/my-account">
                                            <i className="icon-user mr-3"> </i> Profile
                                        </Link>
                                    </ListGroupItem>

                                    <ListGroupItem className={this.props.location.pathname === '/my-orders' ? 'active' : ''}>
                                        <Link to="/my-orders">
                                            <i className="icon-grid mr-3"> </i> My Orders
                                        </Link>
                                    </ListGroupItem>
                                    <li className="list-group-item">
                                        <Link to="/create-order">
                                            <i className="icon-note mr-3"> </i> Create Order
                                        </Link>
                                    </li>
                                    <ListGroupItem className={this.props.location.pathname === '/user/change-passowrd' ? 'active' : ''}>
                                        <Link to="/user/change-passowrd">
                                            <i className="icon-grid mr-3"> </i> Change Password
                                        </Link>
                                    </ListGroupItem>
                                
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="sidebar-widgets sidebar-menu">
                        <Card className="border-0 rounded-0 mb-4">
                            <CardBody className="p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <a href="./logout">
                                            <i className="icon-power mr-3"> </i> Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </div>
                </aside>
            </>
        );
    }
}

export default UserSidebar;