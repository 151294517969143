import axios from "axios";
import { getDecodedJWToken, getServerinfo } from "./Auth";
//import { authHeader } from "../functions/Auth";

/*axios.interceptors.request.use(function (config) {
    const token = getJWToken;
    config.headers.common['Authorization'] =  token;

    return config;
});*/

const api_url = getServerinfo().base_url;
const serverHost = getServerinfo().host;
const serverPort = getServerinfo().port;

const instance = axios.create({
  baseURL: api_url,
});

const myInterceptor = instance.interceptors.request.use(function (config) {
  config.proxy.host = serverHost;
  config.proxy.port = serverPort;

  return config;
});
instance.interceptors.request.eject(myInterceptor);

export const getAddress = (suggestion, countryCode) => {
  const formdata = new FormData();
  formdata.append("search", suggestion);
  formdata.append("countryCode", countryCode);

  return instance({
    method: "post",
    url: "autocomplete_address",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.data.data) {
        resposnse.data.data.filter((result) => {
          res.push({
            label: result.text,
            description: result.description,
            key: result.id,
          });
          return true;
        });
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAddressDetail = (addressId) => {
  const formdata = new FormData();
  formdata.append("address_id", addressId);

  return instance({
    method: "post",
    url: "address_detail",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTimeSlots = () => {
  return instance({
    method: "post",
    url: "time_slots",
    data: "",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getRates = (form_data) => {
  const formdata = new FormData();
  formdata.append("user_id", form_data["userId"]);
  // formdata.append('user_detail[first_name]', this.state.first_name);
  // formdata.append('user_detail[last_name]', this.state.last_name);
  // formdata.append('user_detail[business_name]', (this.state.selectedType === 'enterprise') ? this.state.business_name : '');
  // formdata.append('user_detail[phone]', this.state.phone_number);
  // formdata.append('user_detail[email]', this.state.email);

  const isQuickQuote =
    typeof form_data["quick_quote"] !== "undefined" &&
    form_data["quick_quote"] === "yes"
      ? true
      : false;

  let rateUrl = "";
  if (isQuickQuote) {
    rateUrl = "get_quick_rates";

    formdata.append("origin_postcode", form_data["originPostCode"]);
    formdata.append("origin_country", form_data["originCountry"]);
    formdata.append("origin_state", form_data["originState"]);
    formdata.append("origin_city", form_data["originCity"]);

    formdata.append("destination_postcode", form_data["destinationPostCode"]);
    formdata.append("destination_country", form_data["DestinationCountry"]);
    formdata.append("destination_state", form_data["destinationState"]);
    formdata.append("destination_city", form_data["destinationCity"]);
  } else {
    rateUrl = "get_rates";

    formdata.append("pickup_address[first_name]", form_data["senderFirstName"]);
    formdata.append("pickup_address[last_name]", form_data["senderLastName"]);
    formdata.append(
      "pickup_address[street1]",
      form_data["pickUpAddress_street1"]
    );
    formdata.append(
      "pickup_address[street2]",
      form_data["pickUpAddress_street2"]
    );
    formdata.append(
      "pickup_address[street3]",
      form_data["pickUpAddress_street3"]
    );
    formdata.append("pickup_address[city]", form_data["pickUpAddress_city"]);
    formdata.append(
      "pickup_address[state_code]",
      form_data["pickUpAddress_state_code"]
    );
    formdata.append(
      "pickup_address[post_code]",
      form_data["pickUpAddress_post_code"]
    );
    formdata.append(
      "pickup_address[country_code]",
      form_data["pickUpAddress_country_code"]
    );
    formdata.append("pickup_address[full_address]", form_data["pickUpAddress"]);
    formdata.append(
      "pickup_address[pick_up_slot]",
      form_data["senderPickUpTimeSlot"]
    );
    formdata.append(
      "pickup_address[pick_up_place]",
      form_data["senderPickUpFrom"]
    );

    formdata.append("drop_address[first_name]", form_data["receiverFirstName"]);
    formdata.append("drop_address[last_name]", form_data["receiverLastName"]);
    formdata.append("drop_address[phone]", form_data["receiverPhone"]);
    formdata.append(
      "drop_address[street1]",
      form_data["dropOffAddress_street1"]
    );
    formdata.append(
      "drop_address[street2]",
      form_data["dropOffAddress_street2"]
    );
    formdata.append(
      "drop_address[street3]",
      form_data["dropOffAddress_street3"]
    );
    formdata.append("drop_address[city]", form_data["dropOffAddress_city"]);
    formdata.append(
      "drop_address[state_code]",
      form_data["dropOffAddress_state_code"]
    );
    formdata.append(
      "drop_address[post_code]",
      form_data["dropOffAddress_post_code"]
    );
    formdata.append(
      "drop_address[country_code]",
      form_data["dropOffAddress_country_code"]
    );
    formdata.append("drop_address[full_address]", form_data["dropOffAddress"]);
  }
  formdata.append("parcel[weight]", form_data["parcelWeight"]);
  formdata.append("parcel[width]", form_data["parcelWidth"]);
  formdata.append("parcel[height]", form_data["parcelHeight"]);
  formdata.append("parcel[length]", form_data["parcelLength"]);

  return instance({
    method: "post",
    url: rateUrl,
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      // if(resposnse.data.status){
      //     res = resposnse.data.data;
      // }
      return resposnse.data;
      // return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getState = () => {
  return axios
    .get("./Data/states.json")
    .then((resposnse) => {
      return resposnse.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const placeOrder = (form_data, rate_data, payment_obj) => {
  const userData = getDecodedJWToken();

  const senderInfo =
    typeof form_data.senderInfo !== "undefined" ? form_data.senderInfo : {};
  const receiverInfo =
    typeof form_data.receiverInfo !== "undefined" ? form_data.receiverInfo : {};
  const formdata = new FormData();
  formdata.append("user_id", userData.userId);
  // formdata.append('user_detail[first_name]', this.state.first_name);
  // formdata.append('user_detail[last_name]', this.state.last_name);
  // formdata.append('user_detail[business_name]', (this.state.selectedType === 'enterprise') ? this.state.business_name : '');
  // formdata.append('user_detail[phone]', this.state.phone_number);
  // formdata.append('user_detail[email]', this.state.email);

  // formdata.append('pickup_address[first_name]', form_data.senderFirstName);
  // formdata.append('pickup_address[last_name]', form_data.senderLastName);
  // formdata.append('pickup_address[street1]', form_data.pickUpAddress_street1);
  // formdata.append('pickup_address[street2]', form_data.pickUpAddress_street2);
  // formdata.append('pickup_address[street3]', form_data.pickUpAddress_street3);
  // formdata.append('pickup_address[city]', form_data.pickUpAddress_city);
  // formdata.append('pickup_address[state_code]', form_data.pickUpAddress_state_code);
  // formdata.append('pickup_address[post_code]', form_data.pickUpAddress_post_code);
  // formdata.append('pickup_address[country_code]', form_data.pickUpAddress_country_code);
  // formdata.append('pickup_address[full_address]', form_data.pickUpAddress);
  // formdata.append('pickup_address[pick_up_slot]', form_data.senderPickUpTimeSlot);
  // formdata.append('pickup_address[pick_up_place]', form_data.senderPickUpFrom);

  formdata.append("pickup_address[name]", senderInfo.name);
  formdata.append("pickup_address[email]", senderInfo.email);
  formdata.append("pickup_address[phone]", senderInfo.phone);
  formdata.append("pickup_address[street1]", senderInfo.address1);
  formdata.append("pickup_address[street2]", senderInfo.address2);
  // formdata.append('pickup_address[street3]', form_data.pickUpAddress_street3);
  formdata.append("pickup_address[city]", senderInfo.city);
  formdata.append("pickup_address[state_code]", senderInfo.state);
  formdata.append("pickup_address[post_code]", senderInfo.zipcode);
  formdata.append("pickup_address[country_code]", senderInfo.country);
  formdata.append("pickup_address[full_address]", "");
  formdata.append("pickup_address[pick_up_slot]", "");
  formdata.append("pickup_address[pick_up_place]", "");

  // formdata.append('drop_address[first_name]', form_data.receiverFirstName);
  // formdata.append('drop_address[last_name]', form_data.receiverLastName);
  // formdata.append('drop_address[phone]', form_data.receiverPhone);
  // formdata.append('drop_address[street1]', form_data.dropOffAddress_street1);
  // formdata.append('drop_address[street2]', form_data.dropOffAddress_street2);
  // formdata.append('drop_address[street3]', form_data.dropOffAddress_street3);
  // formdata.append('drop_address[city]', form_data.dropOffAddress_city);
  // formdata.append('drop_address[state_code]', form_data.dropOffAddress_state_code);
  // formdata.append('drop_address[post_code]', form_data.dropOffAddress_post_code);
  // formdata.append('drop_address[country_code]', form_data.dropOffAddress_country_code);
  // formdata.append('drop_address[full_address]', form_data.dropOffAddress);

  formdata.append("drop_address[name]", receiverInfo.name);
  formdata.append("drop_address[email]", receiverInfo.email);
  formdata.append("drop_address[phone]", receiverInfo.phone);
  formdata.append("drop_address[street1]", receiverInfo.address1);
  formdata.append("drop_address[street2]", receiverInfo.address2);
  // formdata.append('drop_address[street3]', form_data.dropOffAddress_street3);
  formdata.append("drop_address[city]", receiverInfo.city);
  formdata.append("drop_address[state_code]", receiverInfo.state);
  formdata.append("drop_address[post_code]", receiverInfo.zipcode);
  formdata.append("drop_address[country_code]", receiverInfo.country);
  formdata.append("drop_address[full_address]", "");

  formdata.append("parcel[weight]", form_data.parcelWeight);
  formdata.append("parcel[width]", form_data.parcelWidth);
  formdata.append("parcel[height]", form_data.parcelHeight);
  formdata.append("parcel[length]", form_data.parcelLength);

  formdata.append("selected_rate[api]", rate_data.api);
  formdata.append("selected_rate[service_type]", rate_data.service_type);
  formdata.append(
    "selected_rate[service_type_name]",
    rate_data.service_type_name
  );
  formdata.append(
    "selected_rate[service_delivery_date]",
    rate_data.service_delivery_date
  );
  formdata.append(
    "selected_rate[service_delivery_day]",
    rate_data.service_delivery_day
  );
  formdata.append("selected_rate[rate_type]", rate_data.rate_type);
  formdata.append("selected_rate[rate_scale]", rate_data.rate_scale);
  formdata.append("selected_rate[rate_zone]", rate_data.rate_zone);
  formdata.append("selected_rate[pricing_code]", rate_data.pricing_code);
  formdata.append("selected_rate[dim_divisor]", rate_data.dim_divisor);
  formdata.append("selected_rate[rate]", rate_data.rate);

  formdata.append("payment", JSON.stringify(payment_obj));

  return instance({
    method: "post",
    url: "create_order",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.data.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOrderDetail = (orderId) => {
  const formdata = new FormData();
  formdata.append("order_id", orderId);

  return instance({
    method: "post",
    url: "order_detail",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgotPassword = (email) => {
  const formdata = new FormData();
  formdata.append("email", email);

  return instance({
    method: "post",
    url: "forgot_password",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const contactUs = (formData) => {
  const formdata = new FormData();
  formdata.append("firstName", formData.firstName);
  formdata.append("lastName", formData.lastName);
  formdata.append("phone", formData.phone);
  formdata.append("email", formData.email);
  formdata.append("companyName", formData.companyName);
  formdata.append("message", formData.sendMessage);

  return instance({
    method: "post",
    url: "contact_us",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCountryLists = () => {
  return instance({
    method: "get",
    url: "country_lists",
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getStateLists = (country_code) => {
  return instance({
    method: "get",
    url: "state_lists",
    params: { country_code: country_code },
    proxy: {
      host: serverHost,
      port: serverPort,
    },
  })
    .then((resposnse) => {
      let res = [];
      if (resposnse.status) {
        res = resposnse.data;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
