const api_url = 'https://api.stripe.com/v1/';
const api_key = "sk_test_4DcchMUw9RXn8VeO2pfmFUrS"; //test
// const api_key = "sk_live_9jC61q0siBRacJIbXKtOBrFw"; //live

const converFormBody = params => {
    var formBody = [];
    for (var property in params) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
}

export const generateToken = cardDetails => {
    const formBody = converFormBody(cardDetails);
    var apiResponse = {status: 'F', message: "", data: ''};

    return fetch(api_url+'tokens', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + api_key,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody
    }).then((response) => response.json())
        .then((res) => {
            if (typeof (res.object) !== "undefined") {
                if (res.object === 'token') {
                    apiResponse.status = "S";
                    apiResponse.data = res;
                    return apiResponse;
                }
                else {
                    apiResponse.message = "Card details are wrong.";
                    return apiResponse;
                }
            } else {
                apiResponse.message = "Card details are wrong.";
                return apiResponse;
            }        
        })
        .catch(err => {
            // console.log(err);          
        })
}

export const makePayment = paymentDetails => {
    const formBody = converFormBody(paymentDetails);
    var apiResponse = {status: 'F', message: "", data: ''};

    return fetch(api_url+'charges', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + api_key,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody
    }).then((response) => response.json())
        .then((res) => {
            if (typeof (res.paid) !== "undefined") {
                if (res.paid === true) {
                    apiResponse.status = "S";
                    apiResponse.data = res;
                    return apiResponse;
                }
                else {
                    apiResponse.message = "Payment failed. Please try after some time.";
                    return apiResponse;
                }
            } else {
                apiResponse.message = "Payment failed. Please try after some time.";
                return apiResponse;
            }
        })
        .catch(err => {
            console.log(err);
        });
}