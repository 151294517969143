import React from "react";
import {
    Container,
    Row,
    Col,
} from "reactstrap";

function HowWorks() {
    return (
        <>

            <section className="process-section py-4 py-md-5">
                <Container>
                    <div className="title mb-2 mb-md-4 text-center">
                        <h2 className="title-text">It's simple to get started</h2>
                        <p className="lead">Whether you're connecting your store or manually entering<br />
                            your orders, you can start shipping in minutes</p>
                    </div>

                    <Row className="mt-5">
                        <Col md={4} className="process-module mb-4">
                            <div className="process-card p-3 h-100">
                                <Row className="align-items-center my-4">
                                    <div className="col-auto">
                                        <div className="process-icon">
                                            <img src={require("../../../assets/images/assets/quote-it-icon.png")} alt="Sign Up" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5>Sign Up</h5>
                                    </div>
                                </Row>
                                <div className="process-number">1</div>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel sit amet nibh libero, in
                                    gravida nulla. vel metus scelerisque.</p>
                            </div>
                        </Col>
                        <Col md={4} className="process-module mb-4">
                            <div className="process-card p-3 h-100">
                                <Row className="align-items-center my-4">
                                    <div className="col-auto">
                                        <div className="process-icon">
                                            <img src={require("../../../assets/images/assets/sign-up-icon.png")} alt="Quote It" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5>Quote It</h5>
                                    </div>
                                </Row>
                                <div className="process-number">2</div>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel sit amet nibh libero, in
                                    gravida nulla. vel metus scelerisque.</p>
                            </div>
                        </Col>
                        <Col md={4} className="process-module mb-4">
                            <div className="process-card p-3 h-100">
                                <Row className="align-items-center my-4">
                                    <div className="col-auto">
                                        <div className="process-icon">
                                            <img src={require("../../../assets/images/assets/ship-it-icon.png")} alt="Quote It" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5>Ship It</h5>
                                    </div>
                                </Row>
                                <div className="process-number">3</div>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel sit amet nibh libero, in
                                    gravida nulla. vel metus scelerisque.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
}

export default HowWorks;