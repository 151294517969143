import jwt_decode from "jwt-decode";

export function getServerinfo() {
  const server = {
    base_url: "http://shipeazy.ca/app/rest/api/",
    host: "72.167.84.16",
    // 'host': '107.180.40.126',
    post: 80,
  };

  return server;
}

export function authHeader() {
  // return authorization header with jwt token
  const token = getJWToken();
  if (token) {
    const decoded = decodeJWToken(token);
    if (decoded && decoded.userId > 0) {
      // return 'Bearer ' + token;
      return {};
    } else {
      return {};
    }
  }
}

export function checkJWToken() {
  const token = getJWToken();

  if (typeof token !== undefined) {
    const decoded = decodeJWToken(token);

    if (decoded && decoded.userId > 0) {
      if (decoded.isLocked === 1) {
        // Checking if user is locked
        clearJWToken();
        return false;
      } else if (decoded.isVerified === 0) {
        // Checking if user is verifed
        clearJWToken();
        return false;
      } else {
        return true;
      }
    } else {
      clearJWToken();
      return false;
    }
  } else {
    clearJWToken();
    return false;
  }
}

export function getJWToken() {
  let userData;
  if (localStorage.user && localStorage.user !== "undefined") {
    userData = localStorage.user;
  } else {
    userData = "";
  }

  return userData;
}

export function decodeJWToken(userData) {
  let decoded = "";
  if (userData) {
    decoded = JSON.parse(userData);
  }

  return decoded;
}

export function getDecodedJWToken() {
  let token = getJWToken();
  let decoded = "";
  if (token) {
    decoded = JSON.parse(token);
  }

  return decoded;
}

export function clearJWToken() {
  localStorage.removeItem("user");
}
