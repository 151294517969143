import React from "react";
import { Row, Col, Button, Input, InputGroup } from "reactstrap";

function Newslatter() {

  return (
    <>

        <div className="newsletter-section">
            <Row>
                <Col>
                    <h6 className="footer-widget-title text-white">Newsletter</h6>
                    <p className="text-gray">Keep up to date on the latest news on shipping, feature updates and everything new and exciting in the eCommerce space.</p>
                </Col>
                <Col md={5}>
                    <InputGroup className="input-group mb-2">
                        <Input type="text" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <div className="input-group-append">
                            <Button color="primary" type="button" id="button-addon2">Submit</Button>
                        </div>
                    </InputGroup>
                    <p className="small m-0 text-gray font-italic">We don't do spam and Your mail id very confidential.</p>
                </Col>
            </Row>
        </div>

    </>
  );
}

export default Newslatter;
