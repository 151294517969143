import React from "react";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import themeStyle from './components/general/ThemeStyle';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
} from "reactstrap";

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import AlertMessages from "./components/general/AlertMessages";

import { forgotPassword } from "../functions/APIRequest";

class ForgotPassword extends React.Component{
    constructor() {
        super();
        this.state = {
            showMessage : '',
            isLoading: false,
            messageColor:'',
            email: '',
        }

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e){
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        document.body.classList.add('forgot-pass');        
    }
    componentWillUnmount() {
        document.body.classList.remove('forgot-pass');
    }

    handleSubmit( e ){
        e.preventDefault();

        
        this.setState({isLoading: true});
        forgotPassword( this.state.email ).then(res => {
            if(res){
                this.setState({messageColor: (res.status) ? 'success' : 'danger', showMessage: res.message});
            
                setTimeout(function(){
                    this.setState({showMessage:'', messageColor: '', email: ''});
                    
                    if (res.status) {                        
                        this.props.history.push('/login');
                    }

                }.bind(this),5000);
                this.setState({isLoading: false});
            }
        })
    }

    render() {
        return (
            <>
                <Header/>

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">Forgot Password</h2>                        
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={6}>

                                {this.state.showMessage !== "" ?
                                    <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                    : ''}

                                <Card className="border-0 rounded-0 login-form">
                                    <CardBody className="p-4">
                                        <ValidatorForm
                                            ref="form"
                                            onSubmit={this.handleSubmit}
                                            onError={errors => console.log(errors)}
                                        >
                                        {/*<Form action="" method="post" noValidate onSubmit={this.handleSubmit}>*/}
                                            <FormGroup className="fancy-form-group">
                                                <TextValidator
                                                    label="Email"
                                                    onChange={this.onChange}
                                                    style={themeStyle.textField}
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['this field is required', 'email is not valid']}
                                                />
                                            </FormGroup>

                                            <div className="form-group">
                                                {/*<button className="btn btn-block btn-success">Login</button>*/}
                                                <Button
                                                    color="success"
                                                    className="btn-block"
                                                    disabled={this.state.isLoading}
                                                >
                                                    {this.state.isLoading ? 'Loading…' : 'Submit'}
                                                </Button>
                                            </div>
                                        {/*</Form>*/}
                                        </ValidatorForm>
                                    </CardBody>
                                </Card>

                                <div className="mt-4">
                                    <p className="text-gray text-center">
                                    Already have an account? Please <Link to="./login">Login here</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer/>
            </>
        );
    }
}

export default ForgotPassword;