import React from "react";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import themeStyle from "./components/general/ThemeStyle";
import { checkJWToken } from "../functions/Auth";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
} from "reactstrap";

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import AlertMessages from "./components/general/AlertMessages";

import { login } from "../functions/userRequests";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      showMessage: "",
      isLoading: false,
      messageColor: "",
      email: "",
      password: "",
      urlParams: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    document.body.classList.add("login");
    if (checkJWToken()) {
      this.props.history.push("/");
    }

    const urlParams = queryString.parse(this.props.location.search);
    this.setState({ urlParams: urlParams });
  }
  componentWillUnmount() {
    document.body.classList.remove("login");
  }

  handleSubmit(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password,
    };

    this.setState({ isLoading: true });
    login(user).then((res) => {
      if (res) {
        this.setState({ messageColor: res.status ? "success" : "danger" });
        this.setState({ showMessage: !res.status ? res.message : "" });
        if (res.status) {
          if (typeof this.state.urlParams.redirect !== "undefined") {
            this.props.history.push(this.state.urlParams.redirect);
          } else {
            this.props.history.push("/");
          }
        }
        setTimeout(
          function () {
            this.setState({ showMessage: "" });
            this.setState({ messageColor: "" });
          }.bind(this),
          5000
        );
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    return (
      <>
        <Header />

        <div className="pageheader-wrapper py-5">
          <Container>
            <h2 className="banner-title mb-md-3 text-white">Login</h2>
            <p className="banner-para text-white">
              {" "}
              Welcome Back, Please login to your account
            </p>
          </Container>
        </div>

        <section className="content-wrapper py-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                {this.state.showMessage !== "" ? (
                  <AlertMessages
                    color={this.state.messageColor}
                    message={this.state.showMessage}
                  />
                ) : (
                  ""
                )}

                <Card className="border-0 rounded-0 login-form">
                  <CardBody className="p-4">
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleSubmit}
                      // onError={errors => console.log(errors)}
                    >
                      {/*<Form action="" method="post" noValidate onSubmit={this.handleSubmit}>*/}
                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Email"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="email"
                          id="email"
                          value={this.state.email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "this field is required",
                            "email is not valid",
                          ]}
                        />
                      </FormGroup>

                      <FormGroup className="fancy-form-group">
                        <TextValidator
                          label="Password"
                          onChange={this.onChange}
                          style={themeStyle.textField}
                          name="password"
                          id="password"
                          type="password"
                          value={this.state.password}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                        />
                      </FormGroup>

                      <FormGroup className="forgot-password">
                        <a href="./" className="small">
                          Forgot password?
                        </a>
                        <Link to="./forgot-password" className="small">
                          Forgot password?
                        </Link>
                      </FormGroup>
                      <div className="form-group">
                        {/*<button className="btn btn-block btn-success">Login</button>*/}
                        <Button
                          color="success"
                          className="btn-block"
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading ? "Loading…" : "Login"}
                        </Button>
                      </div>
                      {/*</Form>*/}
                    </ValidatorForm>
                  </CardBody>
                </Card>

                {/* <div className="mt-4">
                                    <p className="text-gray text-center">
                                        Don't have an account yet? <Link to="./register">Create a new account</Link>
                                    </p>
                                </div> */}
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </>
    );
  }
}

export default Login;
