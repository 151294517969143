import React from "react";
import { checkJWToken } from '../../functions/Auth';
import Moment from "react-moment";
import {
    Container,
    Media,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

import Header from "../components/general/Header";
import UserSidebar from "./UserSidebar";
import Loader from "../components/general/Loader";
import Footer from "../components/general/Footer";
import AlertMessages from "../components/general/AlertMessages";

import { orderDetail } from "../../functions/orderRequests";

class OrderDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            showMessage: '',
            isLoading: false,
            messageColor: '',
            order: [],
            rateDetail: {},
            pickUpAddress: {},
            dropOffAddress: {},
            parcel: {},
            paymentData: {},
            errors: {},
            orderDetail: {}
        }
    }

    componentDidMount() {
        document.body.classList.add('my-orders');

        if (!checkJWToken()) {
            this.props.history.push('/login');
        }

        const orderId = this.props.match.params.orderId;
        if (!orderId) {
            this.props.history.push('/my-orders');
        }

        this.setState({isLoading: true});
        orderDetail(orderId).then(res => {
            if (res.status) {
                if (res.data) {
                    var rateDetail = res.data.rate_detail;
                    var pickUpAddress = res.data.pick_up_address;
                    var dropOffAddress = res.data.drop_off_address;
                    var parcel = res.data.parcel;
                    var paymentData = (res.data.payment_data !== '') ? res.data.payment_data : {};

                    this.setState({
                        orderDetail: res.data,
                        order: res.data,
                        rateDetail: rateDetail,
                        pickUpAddress: pickUpAddress,
                        dropOffAddress: dropOffAddress,
                        parcel: parcel,
                        paymentData: paymentData
                    });
                }
            } else {
                this.setState({
                    showMessage: res.message,
                    messageColor: 'danger'
                });
            }
            this.setState({isLoading: false});
        })
    }
    componentWillUnmount() {
        document.body.classList.remove('my-orders');
    }

    getShippingMethodImage(method) {
        console.log(method);
        let img = '';
        switch (method) {
            case 'fedex':
                img = <img src={require('../../assets/images/assets/fedex.png')} className="img-fluid" alt="fedex" />;
                break;
            case 'canada_post':
                img = <img src={require('../../assets/images/assets/canada-post.png')} className="img-fluid" alt="canada_post" />;
                break;
            case 'purolator':
                img = <img src={require('../../assets/images/assets/purolator.png')} className="img-fluid" alt="purolator" />;
                break;
            default:
                img = <img src={require('../../assets/images/assets/fedex.png')} className="img-fluid" alt="fedex" />;
                break;
        }

        return img;
    }

    getCardType(card_type) {
        var imageResource = require('../../assets/images/assets/stp_card_unknown.png')

        if (card_type === 'visa') {
            imageResource = require('../../assets/images/assets/stp_card_visa.png')
        }
        else if (card_type === 'mastercard') {
            imageResource = require('../../assets/images/assets/stp_card_mastercard.png')
        }
        else if (card_type === 'jcb') {
            imageResource = require('../../assets/images/assets/stp_card_jcb.png')
        }
        else if (card_type === 'discover') {
            imageResource = require('../../assets/images/assets/stp_card_discover.png')
        }
        else if (card_type === 'diners') {
            imageResource = require('../../assets/images/assets/stp_card_diners.png')
        }
        else if (card_type === 'amex') {
            imageResource = require('../../assets/images/assets/stp_card_amex.png')
        }

        return imageResource
    }

    render() {
        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">My Orders</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row>

                            <UserSidebar location={this.props.location} />

                            <Col md={8}>
                                <div className="sidebar-widgets">

                                    {/* <Card className="border-0 rounded-0 mb-4">
                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h5" className="mb-0">Order# {this.state.order.id}</CardTitle>
                                            <div className="text-right">
                                                {/* {this.state.order.status == 1 ?
                                                    <span className="orders-status badge badge-success ml-2">Confirmed</span>
                                                    : <span className="orders-status badge badge-warning ml-2">Pending</span>
                                                } * /}
                                                <span className="orders-status badge badge-warning ml-2">{this.state.order.status_text}</span>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                        {this.state.showMessage !== "" ?
                                        <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                        : ''}
                                            <div className="orders-detail">
                                            {(this.state.isLoading) ?
                                                <Loader inline={true} />
                                                :
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-truck text-gray"></i></div>
                                                            <div className="media-body"><span
                                                                className="font-weight-bold small text-gray text-uppercase">Service</span>
                                                                <div className="service-company-logo">
                                                                    {this.getShippingMethodImage(this.state.order.shipping_method)}
                                                                </div>
                                                                <p className="service-company mb-0">{this.state.rateDetail.service_type_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-money text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Price </span>
                                                                <h6 className="font-weight-bold pt-1 small small">${this.state.rateDetail.rate}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-calendar text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Order On </span>
                                                                <h6 className="font-weight-bold pt-1 small">
                                                                    <Moment format="Do MMM, YYYY">
                                                                        {this.state.order.order_date}
                                                                    </Moment>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(this.state.order.delivered_date !== '') ? 
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-calendar text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Delivered On</span>
                                                                <h6 className="font-weight-bold pt-1 small">
                                                                    <Moment format="Do MMM, YYYY">
                                                                        {this.state.order.delivered_date}
                                                                    </Moment>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-map-marker text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Pickup Address </span>
                                                                <h6 className="font-weight-bold pt-1 small">
                                                                    {this.state.pickUpAddress.full_address}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-map-marker text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">delivery Address </span>
                                                                <h6 className="font-weight-bold pt-1 small">
                                                                    {this.state.dropOffAddress.full_address}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-balance-scale text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Weight</span>
                                                                <h6 className="font-weight-bold pt-1 small">{this.state.parcel.weight}lb</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-cube text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Width</span>
                                                                <h6 className="font-weight-bold pt-1 small">{this.state.parcel.width}in</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-cube text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Height</span>
                                                                <h6 className="font-weight-bold pt-1 small">{this.state.parcel.height}in</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="orders-media media">
                                                            <div className="orders-media-icon media-left"><i className="la la-cube text-gray"></i></div>
                                                            <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Length</span>
                                                                <h6 className="font-weight-bold pt-1 small">{this.state.parcel.length}in</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(this.state.order.payment_status === 1) ?
                                                        <div className="col-md-6 mb-3">
                                                            <div className="orders-media media">
                                                                <div className="orders-media-icon media-left"><i className="la la-credit-card text-gray"></i></div>
                                                                <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Card Detail</span>
                                                                    <h6 className="font-weight-bold pt-1 small">
                                                                        <span className="payment-card-logo">
                                                                            <img src={this.getCardType(this.state.paymentData.payment_method_details.card.brand)} className="img-fluid" alt={this.state.paymentData.payment_method_details.card.brand} />
                                                                        </span> - {this.state.paymentData.payment_method_details.card.last4}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}

                                                    {(this.state.order.payment_status === 1) ?
                                                        <div className="col-md-6 mb-3">
                                                            <div className="orders-media media">
                                                                <div className="orders-media-icon media-left"><i className="la la-calendar text-gray"></i></div>
                                                                <div className="media-body"><span className="font-weight-bold small text-gray text-uppercase">Date Paid</span>
                                                                    <h6 className="font-weight-bold pt-1 small">
                                                                        <Moment format="Do MMM, YYYY">
                                                                            {this.state.order.payment_date}
                                                                        </Moment>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}

                                                </div>
                                            }
                                            </div>
                                        </CardBody>
                                    </Card> */}
                                
                                    

                                    <Container>
                                        <Row>

                                            <Col md={12}>

                                                {this.state.showMessage !== "" ?
                                                    <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                                    : ''}

                                                <div className="orders-detail">
                                                    {(this.state.isLoading) ?
                                                        <Loader inline={true} />
                                                        :
                                                        <div>
                                                            <Card className="border-0 rounded-0 mb-4">
                                                                <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                                                    <CardTitle tag="h5" className="mb-0">
                                                                        Order# {this.state.order.id}

                                                                    </CardTitle>
                                                                    <div className="text-right">
                                                                        {/* {this.state.order.status == 1 ?
                                                    <span className="orders-status badge badge-success ml-2">Confirmed</span>
                                                    : <span className="orders-status badge badge-warning ml-2">Pending</span>
                                                } */}
                                                                        <span className="orders-status badge badge-warning ml-2">{this.state.order.status_text}</span>
                                                                    </div>
                                                                </CardHeader>

                                                                <CardBody>

                                                                    <div className="orders-detail">
                                                                        <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                            Sender INFORMATION
                                                    </h6>

                                                                        <hr />
                                                                        {(typeof this.state.orderDetail.pick_up_address !== 'undefined') ?
                                                                            <Row className="py-3">
                                                                                <Col md={6} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-user text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">Name</span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {/* { this.state.orderDetail.pick_up_address.first_name + " " + this.state.orderDetail.pick_up_address.last_name } */}
                                                                                                {this.state.orderDetail.pick_up_address.name}
                                                                                            </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>

                                                                                <Col md={6} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-map-marker text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Pick Up Address
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.pick_up_address.full_address}
                                                                                            </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>

                                                                                {/* <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-home text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Pick Up From
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                            {this.state.orderDetail.pick_up_address.pick_up_place}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col>

                                                            <Col md={6} className="mb-3">
                                                                <Media className="orders-media">
                                                                    <Media left className="orders-media-icon">
                                                                        <i className="la la-calendar text-gray"></i>
                                                                    </Media>
                                                                    <Media body>
                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                            Pick Up Time
                                                                        </span>
                                                                        <h6 className="font-weight-bold pt-1 small">
                                                                        <Moment unix format="Do MMM, YYYY">
                                                                            {this.state.orderDetail.pick_up_address.pickup_slot_array.pickup_date_int}
                                                                        </Moment>
                                                                        &nbsp;
                                                                        {this.state.orderDetail.pick_up_address.pickup_slot_array.pickup_time}
                                                                        </h6>
                                                                    </Media>
                                                                </Media>
                                                            </Col> */}
                                                                            </Row>
                                                                            : ''
                                                                        }
                                                                        <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                            Receiver INFORMATION
                                                    </h6>
                                                                        <hr />
                                                                        {(typeof this.state.orderDetail.drop_off_address !== 'undefined') ?
                                                                            <Row className="py-3">
                                                                                <Col md={6} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-user text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">Name</span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {/* {this.state.orderDetail.drop_off_address.first_name}&nbsp;
                                                                            {this.state.orderDetail.drop_off_address.last_name} */}
                                                                                                {this.state.orderDetail.drop_off_address.name}
                                                                                            </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>

                                                                                <Col md={6} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-phone text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Phone
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.drop_off_address.phone}
                                                                                            </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>

                                                                                <Col md={6} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-map-marker text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Drop Off Address
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.drop_off_address.full_address}
                                                                                            </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>

                                                                            </Row>
                                                                            : ''}
                                                                        <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                            Parcel Information
                                                    </h6>
                                                                        <hr />

                                                                        {(typeof this.state.orderDetail.parcel !== 'undefined') ?
                                                                            <Row className="py-3">
                                                                                <Col md={3} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-balance-scale text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Weight
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.parcel.weight} lb
                                                                        </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>
                                                                                <Col md={3} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-cube text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Width
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.parcel.width} in
                                                                        </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>
                                                                                <Col md={3} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-cube text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Height
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.parcel.height} in
                                                                        </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>
                                                                                <Col md={3} className="mb-3">
                                                                                    <Media className="orders-media">
                                                                                        <Media left className="orders-media-icon">
                                                                                            <i className="la la-cube text-gray"></i>
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Length
                                                                        </span>
                                                                                            <h6 className="font-weight-bold pt-1 small">
                                                                                                {this.state.orderDetail.parcel.length} in
                                                                        </h6>
                                                                                        </Media>
                                                                                    </Media>
                                                                                </Col>
                                                                            </Row>
                                                                            : ''
                                                                        }
                                                                        <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                            Shipping Information
                                                    </h6>

                                                                        <hr />

                                                                        <Row className="py-3">
                                                                            <Col md={6} className="mb-3">
                                                                                <Media className="orders-media">
                                                                                    <Media left className="orders-media-icon">
                                                                                        <i className="la la-truck text-gray"></i>
                                                                                    </Media>
                                                                                    <Media body>
                                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                                            Service
                                                                    </span>
                                                                                        <div className="service-company-logo">
                                                                                            {this.getShippingMethodImage(this.state.orderDetail.shipping_method)}
                                                                                        </div>
                                                                                        {(typeof this.state.orderDetail.rate_detail !== 'undefined') ?
                                                                                            <p className="service-company mb-0">{this.state.orderDetail.rate_detail.service_type_name}</p>
                                                                                            : ''
                                                                                        }
                                                                                    </Media>
                                                                                </Media>
                                                                            </Col>

                                                                            <Col md={6} className="mb-3">
                                                                                <Media className="orders-media">
                                                                                    <Media left className="orders-media-icon">
                                                                                        <i className="la la-money text-gray"></i>
                                                                                    </Media>
                                                                                    <Media body>
                                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                                            Price
                                                                    </span>
                                                                                        <h6 className="font-weight-bold pt-1 small small">
                                                                                            ${this.state.orderDetail.rate}
                                                                                        </h6>
                                                                                    </Media>
                                                                                </Media>
                                                                            </Col>

                                                                            <Col md={6} className="mb-3">
                                                                                <Media className="orders-media">
                                                                                    <Media left className="orders-media-icon">
                                                                                        <i className="la la-calendar text-gray"></i>
                                                                                    </Media>
                                                                                    <Media body>
                                                                                        <span className="font-weight-bold small text-gray text-uppercase">
                                                                                            Order On
                                                                    </span>
                                                                                        <h6 className="font-weight-bold pt-1 small small">
                                                                                            <Moment unix format="Do MMM, YYYY">
                                                                                                {this.state.orderDetail.order_date_int}
                                                                                            </Moment>
                                                                                        </h6>
                                                                                    </Media>
                                                                                </Media>
                                                                            </Col>

                                                                            <Col md={6} className="mb-3">
                                                                                <Media className="orders-media">
                                                                                    <Media left className="orders-media-icon">
                                                                                        <i className="la la-calendar text-gray"></i>
                                                                                    </Media>
                                                                                    {(this.state.orderDetail.delivered_date_int !== '') ?
                                                                                        <Media body>
                                                                                            <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                Delivered On
                                                                    </span>
                                                                                            <h6 className="font-weight-bold pt-1 small small">
                                                                                                <Moment unix format="Do MMM, YYYY">
                                                                                                    {this.state.orderDetail.delivered_date_int}
                                                                                                </Moment>
                                                                                            </h6>
                                                                                        </Media>
                                                                                        :
                                                                                        <Media body>
                                                                                            {(typeof this.state.orderDetail.rate_detail !== "undefined") ?
                                                                                                <div>
                                                                                                    <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                        Expected Delivery Date
                                                                                </span>
                                                                                                    <h6 className="font-weight-bold pt-1 small small">
                                                                                                        <Moment format="Do MMM, YYYY">
                                                                                                            {this.state.orderDetail.rate_detail.service_delivery_date}
                                                                                                        </Moment>
                                                                                                    </h6>
                                                                                                </div>
                                                                                                : ""
                                                                                            }
                                                                                        </Media>
                                                                                    }
                                                                                </Media>
                                                                            </Col>

                                                                        </Row>
                                                                        {(this.state.orderDetail.payment_status) ?
                                                                            <div>
                                                                                <h6 className="font-weight-bold text-gray-dark text-uppercase">
                                                                                    Payment Information
                                                            </h6>

                                                                                <hr />

                                                                                <Row className="py-3">
                                                                                    <Col md={6} className="mb-3">
                                                                                        <Media className="orders-media">
                                                                                            <Media left className="orders-media-icon">
                                                                                                <i className="la la-credit-card text-gray"></i>
                                                                                            </Media>
                                                                                            <Media body>
                                                                                                <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                    Card Detail
                                                                            </span>
                                                                                                <div className="card-company-logo d-flex align-items-center">
                                                                                                    <img src={this.getCardType(this.state.orderDetail.payment_method)} className="img-fluid" alt="fedex" />
                                                                                                    <h6 className="font-weight-bold pt-1 small mb-0">- {this.state.orderDetail.card_no}</h6>
                                                                                                </div>
                                                                                            </Media>
                                                                                        </Media>
                                                                                    </Col>

                                                                                    <Col md={6} className="mb-3">
                                                                                        <Media className="orders-media">
                                                                                            <Media left className="orders-media-icon">
                                                                                                <i className="la la-calendar text-gray"></i>
                                                                                            </Media>
                                                                                            <Media body>
                                                                                                <span className="font-weight-bold small text-gray text-uppercase">
                                                                                                    Date Paid
                                                                            </span>
                                                                                                <h6 className="font-weight-bold pt-1 small small">
                                                                                                    <Moment unix format="Do MMM, YYYY">
                                                                                                        {this.state.orderDetail.payment_date_int}
                                                                                                    </Moment>
                                                                                                </h6>
                                                                                            </Media>
                                                                                        </Media>
                                                                                    </Col>

                                                                                </Row>
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>

                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    }
                                                </div>
                                            </Col>

                                        </Row>
                                    </Container>
                



                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default OrderDetail;