import React from "react";
import { checkJWToken, getDecodedJWToken } from '../../functions/Auth';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

import Header from "../components/general/Header";
import UserSidebar from "./UserSidebar";
import Footer from "../components/general/Footer";

import Pagination from '../components/general/Pagination';

import { myOrders } from "../../functions/orderRequests";
import Loader from "../components/general/Loader";
import OrderListItem from "./OrderListItem";
import AlertMessages from "../components/general/AlertMessages";

class MyOrders extends React.Component {

    state = { currentRows: [], currentPage: null, totalPages: null }

    constructor() {
        super();
        this.state = {
            showMessage: '',
            isLoading: false,
            messageColor: '',
            totalRows: null,
            orders: [],
            errors: {}
        }
    }

    componentDidMount() {
        document.body.classList.add('my-orders');

        if (!checkJWToken()) {
            this.props.history.push('/login');
        }

        this.setState({isLoading: true});
        myOrders(0).then(res => {
            if (res.status === true) {
                if (res.data) {
                    this.setState({
                        orders: res.data,
                        totalRows: res.total_rows
                    });
                }
            } else {
                this.setState({
                    showMessage: res.message,
                    messageColor: 'danger'
                });
            }
            this.setState({isLoading: false});
        })
    }
    componentWillUnmount() {
        document.body.classList.remove('my-orders');
    }

    onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
        let offset = (currentPage - 1) * pageLimit;
        offset = (offset > 0) ? offset : 0;
        this.setState({isLoading: true});
        myOrders(offset).then(res => {
            if (res.status) {
                if (res.data) {
                    this.setState({
                        orders: res.data,
                        totalRows: res.total_rows
                    });
                }
            } else {
                this.setState({
                    showMessage: res.message,
                    messageColor: 'danger'
                });
            }
            this.setState({isLoading: false});
        })

        this.setState({ currentPage, totalPages });
    }

    render() {

        //const { currentRows, currentPage, totalPages } = this.state;

        if (this.state.totalRows === 0) return null;

        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">My Orders</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row>

                            <UserSidebar location={this.props.location} />

                            <Col md={8}>
                                <div className="sidebar-widgets">

                                    <Card className="border-0 rounded-0 mb-4">
                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h5" className="mb-0">My Orders</CardTitle>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                        {this.state.showMessage !== "" ?
                                        <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                        : ''}
                                            <div className="orders-list">

                                            {(this.state.isLoading) ?
                                                <Loader inline={true} />
                                                :
                                                <div>
                                                {this.state.orders.map((item, key) =>
                                                    <OrderListItem item={item} key={item.id} />
                                                )}
                                                </div>
                                            }
                                            </div>
                                        </CardBody>
                                        {/* { currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                </span>
              ) } */}
                                        {this.state.totalRows > 0 ?
                                            <div className="d-flex flex-row align-items-center justify-content-center">
                                                <Pagination totalRecords={this.state.totalRows} pageLimit={10} pageNeighbours={2} onPageChanged={this.onPageChanged} />
                                            </div>
                                            : ''}
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default MyOrders;