import React from "react";
import { checkJWToken } from "../../functions/Auth";
import { ValidatorForm } from "react-material-ui-form-validator";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
} from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import Loader from "../components/general/Loader";

import OrderProcessSidebar from "./OrderProcessSidebar";

import { getRates } from "../../functions/APIRequest";

class GetRates extends React.Component {
  constructor() {
    super();
    this.state = {
      showMessage: "",
      isLoading: false,
      showLoader: false,
      messageColor: "",
      rates: {},
      orderData: {},
      selectedRate: "rate_0",
      selectedRateData: {},
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    document.body.classList.add("orders-process-get-rates");

    let formdata = this.props.history.location.data;
    // if (!checkJWToken()) {
    //   if (typeof formdata === "undefined") {
    //     this.props.history.push("/home");
    //     return false;
    //   } else {
    //     localStorage.setItem("parcel", JSON.stringify(formdata));
    //     this.props.history.push("/login?redirect=/rates");
    //     return false;
    //   }
    // } else {
    if (typeof formdata === "undefined") {
      formdata = JSON.parse(localStorage.getItem("parcel"));
    } else {
      localStorage.setItem("parcel", JSON.stringify(formdata));
    }
    // }

    if (formdata === null) {
      this.props.history.push("/home");
      return false;
    }

    const userData = JSON.parse(localStorage.getItem("user"));
    if (typeof userData === "undefined") {
      this.props.history.push("/home");
      return false;
    }

    // if (typeof formdata === 'undefined') {
    //     this.props.history.push('/create-order');
    //     return false;
    // }

    // localStorage.setItem('parcel', JSON.stringify(formdata));
    formdata["userId"] =
      userData !== null && typeof userData.userId !== "undefined"
        ? userData.userId
        : 0;
    // console.log(formdata);
    await this.setState({ showLoader: true, orderData: formdata });
    getRates(formdata).then((res) => {
      if (res.status) {
        this.setState({ rates: res.data, showLoader: false });
        this.getSelectedRateData(0);
      } else {
        const msg = res.message !== "" ? res.message : "Something went wrong.";
        this.setState({ rates: [], showMessage: msg, showLoader: false });
      }
    });
  }
  componentWillUnmount() {
    document.body.classList.remove("orders-process-get-rates");
  }

  onChange(e) {
    const rate_key =
      typeof e.currentTarget.dataset.key !== "undefined"
        ? e.currentTarget.dataset.key
        : 0;

    this.setState({ selectedRate: e.currentTarget.value });
    this.getSelectedRateData(rate_key);
  }

  getSelectedRateData(rate_key) {
    const rateData = this.state.rates[rate_key];
    this.setState({ selectedRateData: rateData });
  }

  handleSubmit(event) {
    event.preventDefault();

    const orderData = {
      info: this.state.orderData,
      rateData: this.state.selectedRateData,
    };

    localStorage.setItem("orderInfo", JSON.stringify(orderData));
    if (!checkJWToken()) {
      this.props.history.push("/login?redirect=/order/pay");
    } else {
      this.props.history.push("/order/pay");
    }
  }

  getShippingMethodImage(method) {
    let imgResource = "";
    switch (method) {
      case "fedex":
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
      case "canada_post":
        imgResource = require("../../assets/images/assets/canada-post.png");
        break;
      case "purolator":
        imgResource = require("../../assets/images/assets/purolator.png");
        break;
      default:
        imgResource = require("../../assets/images/assets/fedex.png");
        break;
    }

    return imgResource;
  }

  render() {
    return (
      <>
        <Header />

        <div className="pageheader-wrapper py-5">
          <Container>
            <h2 className="banner-title mb-md-3 text-white">
              Shipping Options
            </h2>
          </Container>
        </div>

        <section className="content-wrapper py-5">
          <Container>
            <Row>
              {this.state.orderData && this.state.selectedRateData ? (
                <OrderProcessSidebar
                  orderData={this.state.orderData}
                  rateData={this.state.selectedRateData}
                />
              ) : (
                ""
              )}

              <Col md={8}>
                <Card className="border-0 rounded-0 mb-4 h-100">
                  <CardHeader className="bg-transparent d-md-flex justify-content-between">
                    <CardTitle tag="h5" className="mb-0">
                      Companies that serve you
                    </CardTitle>
                  </CardHeader>

                  <CardBody>
                    <div className="company-listing-wrap">
                      {this.state.showLoader ? (
                        <Loader inline={true} />
                      ) : (
                        <ValidatorForm
                          ref="form"
                          instantValidate={true}
                          onSubmit={this.handleSubmit}
                          // onError={errors => console.log(errors)}
                        >
                          {this.state.rates.length > 0
                            ? this.state.rates.map((rate, _id) => (
                                <div
                                  key={_id}
                                  className={`company-listing ${
                                    _id === 0 ? "active" : ""
                                  }`}
                                >
                                  <Row className="align-items-center justify-content-around">
                                    <Col md={2}>
                                      <div className="custom-control custom-radio">
                                        <Input
                                          type="radio"
                                          id={`rate_${_id}`}
                                          name="rate"
                                          value={`rate_${_id}`}
                                          data-key={_id}
                                          checked={
                                            this.state.selectedRate ===
                                            `rate_${_id}`
                                          }
                                          onChange={this.onChange}
                                          className="custom-control-input"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`rate_${_id}`}
                                        ></label>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="service-company-logo">
                                        <img
                                          src={this.getShippingMethodImage(
                                            rate.api
                                          )}
                                          className="img-fluid"
                                          alt={rate.api}
                                        />
                                        <p className="small">
                                          {rate.service_type_name}
                                        </p>
                                      </div>
                                    </Col>

                                    <Col>{rate.service_delivery_date}</Col>

                                    <Col className="text-md-right">
                                      <h4 className="font-weight-bold service-price">
                                        ${rate.rate}
                                      </h4>
                                      {rate.rate_commission > 0 ? (
                                        <h6 className="service-price strike-line text-muted">
                                          ${rate.rate_commission}
                                        </h6>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                            : ""}

                          {this.state.showMessage !== "" ? (
                            <div>
                              <div className="alert alert-danger">
                                {this.state.showMessage}
                              </div>
                              <div className="mt-4">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    this.props.history.goBack();
                                  }}
                                  className="btn btn-block btn-primary"
                                >
                                  Back
                                </Button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.rates.length > 0 &&
                          this.state.showMessage === "" ? (
                            <div className="mt-4">
                              <Button className="btn btn-block btn-success">
                                Proceed Payment
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </ValidatorForm>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </>
    );
  }
}

export default GetRates;
