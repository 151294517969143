import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import Newslatter from "./Newslatter";

function ListItem(props) {
  const value = props.value;
  return (
    <li>
      <Link to={value.link}>{value.title}</Link>
    </li>
  );
}

function Footer() {
  const companyMenuItems = {
    0: { link: "./about-us", title: "About Us" },
    1: { link: "./blogs", title: "Blogs" },
    2: { link: "./terms-conditions", title: "Terms Of Use" },
  };

  const listItems = Object.keys(companyMenuItems).map((key) => (
    <ListItem key={key.toString()} value={companyMenuItems[key]} />
  ));

  const supportMenuItems = [
    { link: "./faq", title: "Common Questions" },
    { link: "./contact-us", title: "Contact Us" },
    { link: "./claims", title: "Claims" },
  ];

  const supportListItems = Object.keys(supportMenuItems).map((key) => (
    <ListItem key={key.toString()} value={supportMenuItems[key]} />
  ));

  return (
    <>
      <footer className="footer bg-dark-gray pt-4 pt-md-5 pb-2">
        <Container>
          <Newslatter />

          <hr />

          <Row className="pt-3">
            <Col lg={3}>
              <div className="footer-widget text-center text-md-left">
                <h6 className="footer-widget-title text-white">About Us</h6>
                <p className="text-gray">
                  Where you can achieve your financial goals and fulfill your
                  future dreams with minimal risk.
                </p>
                <ul className="social-links list-inline pt-3">
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://facebook.com"
                    >
                      <i className="la la-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com"
                    >
                      <i className="la la-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://plus.google.com"
                    >
                      <i className="la la-google-plus"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://linkedin.com"
                    >
                      <i className="la la-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="col-lg offset-lg-1 text-center text-md-left">
              <div className="footer-widget">
                <h6 className="footer-widget-title text-white">Company</h6>
                <ul className="footer-widget-menu">{listItems}</ul>
              </div>
            </Col>
            <Col className="col-lg offset-lg-1 text-center text-md-left">
              <div className="footer-widget">
                <h6 className="footer-widget-title text-white">Contacts</h6>

                <span className="mail-caption">General Inquiries</span>
                <a href="mailto:inquire@shipeazy.com">inquire@shipeazy.ca</a>

                <span className="mail-caption">Sales</span>
                <a href="mailto:sales@shipeazy.com">sales@shipeazy.ca</a>

                <span className="mail-caption">Operation</span>
                <a href="mailto:operations@shipeazy.com">
                  operations@shipeazy.ca
                </a>
              </div>
            </Col>
            <Col className="col-lg offset-lg-1 text-center text-md-left">
              <div className="footer-widget">
                <h6 className="footer-widget-title text-white">
                  Help &amp; Support
                </h6>
                <ul className="footer-widget-menu">{supportListItems}</ul>
              </div>
            </Col>
          </Row>

          <hr />

          <div className="copyright">
            <Row>
              <Col className="col-lg text-center text-md-left">
                <p className="text-gray">
                  Copyright &copy; {new Date().getFullYear()} Shipeazy, LLC. All
                  Rights Reserved.
                </p>
              </Col>
              <Col className="col-lg text-center text-md-left text-xl-right">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link to="./terms-conditions">Terms &amp; Conditions </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="./privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
