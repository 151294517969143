import React from "react";
import { Container, Row, Col } from "reactstrap";

function DownloadApp() {
  return (
    <>
      <section className="apps-section bg-primary pt-2 pt-md-5">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={5}>
              <div className="title mb-2 mb-md-4">
                <h2 className="title-text text-white">Download App</h2>
                <p className="text-white">
                  For your intra-Canada needs, heavy or lightweight, urgent or
                  less time-sensitive, Shipeazy has the solutions and the
                  reliability you can count on.
                </p>
              </div>
              <Row className="my-4">
                <Col>
                  <a
                    href="https://apps.apple.com/us/app/etapali/id1472837425"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../../assets/images/assets/app_store.png")}
                      alt="download Shipeazy from app store"
                      className="img-fluid"
                    />
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.etapali"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../../assets/images/assets/google_play.png")}
                      alt="download Shipeazy from google play store"
                      className="img-fluid"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="d-none d-md-block">
              <img
                src={require("../../../assets/images/assets/app-screens.png")}
                className="img-fluid"
                alt="download Shipeazy applications"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default DownloadApp;
