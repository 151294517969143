import axios from "axios";
import { authHeader, getDecodedJWToken, getServerinfo } from "../functions/Auth";

const api_url = getServerinfo().base_url;
const serverHost = getServerinfo().host;
const serverPort = getServerinfo().port;

const instance = axios.create({
    baseURL: api_url,
});

const myInterceptor = instance.interceptors.request.use(function (config) {
    config.proxy.host = serverHost;
    config.proxy.port = serverPort;

    return config;
});
instance.interceptors.request.eject(myInterceptor);

export const myOrders = offset => {
    const currentUser = getDecodedJWToken();
    const formdata = new FormData();
    formdata.append('user_id', currentUser.userId);
    formdata.append('offset', offset);

    return instance({
        method: 'post',
        url: 'my_orders',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {        
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    }); 
}

export const orderDetail = orderId => {
    const formdata = new FormData();
    formdata.append('order_id', orderId);

    return instance({
        method: 'post',
        url: 'order_detail',
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        proxy:{
            host: serverHost,
            port: serverPort
        }
    }).then(resposnse => {        
        return resposnse.data;
    })
    .catch(err => {
        console.log(err);
    }); 
}