import React from "react";

class Loader extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            inline: props.inline,
        };
    }

    render() {
        return (
            <>
                <div className={`loader ${(this.state.inline) ? 'inline-loading' : 'box-loading' }`}>
                    <img src={require('../../../assets/images/assets/loader.gif')} alt="Loading..." />
                </div>
            </>
        );
    }
}

export default Loader;