import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
} from "reactstrap";

class OrderListItem extends Component {
    constructor(props) {
        super(props);

        var rateDetail = this.props.item.rate_detail;
        var pickUpAddress = this.props.item.pick_up_address;
        var dropOffAddress = this.props.item.drop_off_address;
        var parcel = this.props.item.parcel;

        this.state = {
            rateDetail: rateDetail,
            pickUpAddress: pickUpAddress,
            dropOffAddress: dropOffAddress,
            parcel: parcel
        }
        
    }

    getShippingMethodImage(method) {
        let img = '';
        switch (method) {
            case 'fedex':
                img = <img src={require('../../assets/images/assets/fedex.png')} className="img-fluid" alt="fedex" />;
                break;
            case 'canada_post':
                img = <img src={require('../../assets/images/assets/canada-post.png')} className="img-fluid" alt="fedex" />;
                break;
            case 'purolator':
                img = <img src={require('../../assets/images/assets/purolator.png')} className="img-fluid" alt="fedex" />;
                break;
            default :
                img = <img src={require('../../assets/images/assets/fedex.png')} className="img-fluid" alt="fedex" />;
            break;
        }

        return img;
    }

    render() {
        return (
            <div className="orders-list-item border mb-3">
                <div className="bg-light1 p-2 d-md-flex justify-content-between align-items-center border-bottom">
                    <h6 className="text-dark font-weight-bold pt-1">
                        Order# {this.props.item.id}
                        {/* {this.props.item.status === 1 ?
                            <span className="orders-status badge badge-success ml-2">Confirmed</span>
                            : <span className="orders-status badge badge-warning ml-2">Pending</span>
                        } */}

                        <span className="orders-status badge badge-warning ml-2">{this.props.item.status_text}</span>
                    </h6>

                    <Link className="order-detail-btn" to={`/order/detail/${this.props.item.id}`}>View Detail</Link>
                </div>
                <div className="bg-white pt-3 px-3">
                    <Row>
                        <Col className="mr-4">
                            <span className="font-weight-bold small text-gray text-uppercase">Service</span>
                            <div className="service-company-logo">
                                {this.getShippingMethodImage(this.state.rateDetail.api)}
                            </div>
                            <p className="service-company mb-0">{this.state.rateDetail.service_type_name}</p>
                        </Col>
                        <Col>
                            <span className="font-weight-bold small text-gray text-uppercase">Price</span>
                            <h6 className="font-weight-bold pt-1 small">${this.state.rateDetail.rate}</h6>
                        </Col>
                        <Col>
                            <span className="font-weight-bold small text-gray text-uppercase">Order On</span>
                            <h6 className="font-weight-bold pt-1 small">
                                <Moment format="Do MMM, YYYY">
                                    {this.props.item.order_date}
                                </Moment>
                            </h6>
                        </Col>
                        <Col>
                            <span className="font-weight-bold small text-gray text-uppercase">Delivered On</span>
                            <h6 className="font-weight-bold pt-1 small">
                                <Moment format="Do MMM, YYYY">
                                    {this.props.item.delivered_date}
                                </Moment>
                            </h6>
                        </Col>
                    </Row>
                </div>
                <div className="orders-shiping-address p-3 m-3">
                    <Row>
                        <Col className="shiping-pickup-address">
                            <span className="font-weight-bold small text-gray text-uppercase">Pickup Address</span>
                            <h6 className="font-weight-bold pt-1 small">{this.state.pickUpAddress.full_address}</h6>
                        </Col>
                        {this.props.item.shipping_status === 1 ?
                            <div className="col-3 align-self-center text-center shiping-icon">
                                <i className="icon-check text-gray"></i>
                                <div className="shipping-status text-success">Delivered</div>
                            </div>
                            :
                            <div className="col-3 align-self-center text-center shiping-icon">
                                <i className="icon-arrow-right text-gray"></i>
                                <div className="shipping-status"> In Transit</div>
                            </div>
                        }
                        <Col className="col shiping-delivery-addres">
                            <span className="font-weight-bold small text-gray text-uppercase">Delivery Address</span>
                            <h6 className="font-weight-bold pt-1 small">{this.state.dropOffAddress.full_address}</h6>
                        </Col>
                    </Row>
                </div>

            </div>

        );
    }
}

export default OrderListItem;