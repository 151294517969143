import React from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import { checkJWToken } from '../../../functions/Auth';
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";

// import logo from "../../../assets/images/logo/etapali-logo.svg";
import logo from "../../../assets/images/logo/Shipeaz_Logo.png";

// function IndexNavbar() {
class IndexNavbar extends React.Component {
    // const [navbarCollapse, setNavbarCollapse] = React.useState(false);

    // const toggleNavbarCollapse = () => {
    //     setNavbarCollapse(!navbarCollapse);
    //     document.documentElement.classList.toggle("nav-open");
    // };

    constructor(props) {
        super(props);
        this.state = {
            navbarCollapse: false
        }
        this.toggleNavbarCollapse = this.toggleNavbarCollapse.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    toggleNavbarCollapse() {
        this.setState({ navbarCollapse: !this.state.navbarCollapse });
        document.documentElement.classList.toggle("nav-open");
    }

    logOut(e) {
        e.preventDefault()
        localStorage.removeItem('user');
        localStorage.removeItem('parcel');
        this.props.history.push('/');
    }

    render() {
        return (
            <Navbar className="px-md-0" expand="lg">
                <Container fluid={true} className="pr-md-0">
                    <NavbarBrand href="./home">
                        <img src={logo} alt="eTapali" className="img-fluid" />
                    </NavbarBrand>
                    <button
                        aria-expanded={this.state.navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: this.state.navbarCollapse
                        })}
                        onClick={this.toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <Collapse
                        id="navbarNav"
                        navbar
                        isOpen={this.state.navbarCollapse}
                    >
                        <Nav navbar className="main-nav ml-auto text-uppercase">
                            <NavItem>
                                {/*<NavLink tag={Link} to="/">*/}
                                <NavLink href="./home">
                                    Home
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="./about-us">
                                    About
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    Features
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    How It Works
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="./contact-us">
                                    Contact Us
                            </NavLink>
                            </NavItem>
                        </Nav>
                        {checkJWToken() ?
                            <div>
                                <Link className="btn btn-secondary btn-signin" to="./my-account">My Account</Link>
                                <a className="btn btn-danger btn-signin" onClick={this.logOut} href="./logout">Sign Out</a>
                            </div>
                            :
                            <div>
                                <Link className="btn btn-secondary btn-signin" to="./login">Sign In</Link>
                                {/* <Link className="btn btn-primary btn-signup" to="./register">Sign Up</Link> */}
                            </div>
                        }
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default withRouter(IndexNavbar);