import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Media
} from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";

import Partners from "../components/Home/Partners";
import ComingSoon from "../components/general/ComingSoon";

class Claims extends Component {
  
    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            members: []
        }
    }


    render()
    {
        return (
            <>
                <Header/>

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">Claims</h2>
                    </Container>
                </div>

                <section className="contact-section py-5">
                    <Container>                        
                        <Card>
                            <CardBody className="p-0">
                                <ComingSoon/>
                            </CardBody>
                        </Card>
                    </Container>
                </section>

                
                <Partners/>
                <Footer/>
            </>
        );
    }
}

export default Claims;
