/*eslint-disable*/
import React from "react";
import { Button, Container } from "reactstrap";

import Navbar from "./Navbar";
import classnames from "classnames";

function Header() {
    const [headerClass, setHeaderClass] = React.useState("");

    React.useEffect(() => {
        const updateHeaderCalss = () => {
            if (
                document.documentElement.scrollTop > 10 ||
                document.body.scrollTop > 10
            ) {
                setHeaderClass("sticky");
            } else if (
                document.documentElement.scrollTop < 10 ||
                document.body.scrollTop < 10
            ) {
                setHeaderClass("");
            }
        };

        window.addEventListener("scroll", updateHeaderCalss);

        return function cleanup() {
            window.removeEventListener("scroll", updateHeaderCalss);
        };
    });

  return (
    <>
        <header id="header" className={classnames("header", headerClass)}>
            <Navbar/>
        </header>
    </>
  );
}

export default Header;
