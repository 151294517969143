import React from "react";
import themeStyle from "../general/ThemeStyle";
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  getState,
  getCountryLists,
  getStateLists,
} from "../../../functions/APIRequest";
import {
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { validate } from "postal-codes-js";

// import AutoCompleteAddress from "../general/AutoCompleteAddress";
import { getTimeSlots } from "../../../functions/APIRequest";

/*function Banner() {*/
class Banner extends React.Component {
  canadaPostCodeRegEx = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]s?[0-9][A-Z][0-9]$/;
  USPostCodeRegEx = /(^d{5}$)|(^d{5}-d{4}$)/;

  optionalFieldDisable = false;
  constructor() {
    super();

    // if (ValidatorForm.hasValidationRule("isValidPostCode")) {
    ValidatorForm.addValidationRule("isValidPostCode", (value) => {
      const { DestinationCountry } = this.state;
      if (DestinationCountry === "") {
        return true;
      }
      let isValid = validate(DestinationCountry, value);
      isValid = isValid === true ? true : false;

      if (!isValid) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isValidShipPostCode", (value) => {
      const originCountry = "CA";
      if (originCountry === "") {
        return true;
      }
      let isValid = validate(originCountry, value);
      isValid = isValid === true ? true : false;

      if (!isValid) {
        return false;
      }
      return true;
    });

    // }
    this.state = {
      showMessage: "",
      isLoading: false,
      messageColor: "",

      originCountry: "",
      originState: "",
      originCity: "",
      originPostCode: "",
      originPostCodeError: false,

      allStates: {},
      countryStates: [],

      countryLists: [],
      destinationStateLists: [],
      originStateLists: [],

      DestinationCountry: "",
      destinationCity: "",
      destinationState: "",
      destinationPostCode: "",

      packageType: 101,

      parcelWeight: "",
      parcelWidth: "",
      parcelHeight: "",
      parcelLength: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onPackageTypeChange = this.onPackageTypeChange.bind(this);
    this.onChangePostCode = this.onChangePostCode.bind(this);
    this.onDestinationCountryChange =
      this.onDestinationCountryChange.bind(this);

    // this.onpickUpCountryChange = this.onpickUpCountryChange.bind(this);
  }

  async componentDidMount() {
    //document.body.classList.add('home');

    getTimeSlots().then((res) => {
      if (res.status) {
        const data = res.data;
        this.setState({
          pickUpPlaces: data.pickup_place,
          timeSlots: data.timeSlots,
        });
      }
    });

    // getState().then((res) => {
    //   this.setState({ allStates: res });
    //   const country = "CA";
    //   const selectedStates =
    //     this.state.allStates[country] !== "undefined"
    //       ? this.state.allStates[country]
    //       : [];

    //   this.setState({ countryStates: selectedStates });

    //   const formdata = JSON.parse(localStorage.getItem("parcel"));
    //   if (typeof formdata !== "undefined" && formdata !== null) {
    //     this.setState({
    //       originCountry: formdata.originCountry,
    //       originState: formdata.originState,
    //       originCity: formdata.originCity,
    //       originPostCode: formdata.originPostCode,

    //       DestinationCountry: formdata.DestinationCountry,
    //       destinationCity: formdata.destinationCity,
    //       destinationState: formdata.destinationState,
    //       destinationPostCode: formdata.destinationPostCode,

    //       parcelWeight: formdata.parcelWeight,
    //       parcelWidth: formdata.parcelWidth,
    //       parcelHeight: formdata.parcelHeight,
    //       parcelLength: formdata.parcelLength,

    //       packageType: formdata.packageType,
    //     });

    //     // localStorage.removeItem('parcel');
    //   }
    // });

    getCountryLists().then((res) => {
      if (res.status) {
        const data = res.data;
        this.setState({ countryLists: data });
      }
    });

    await this.getStateLists("CA", "from");

    const formdata = JSON.parse(localStorage.getItem("parcel"));
    if (typeof formdata !== "undefined" && formdata !== null) {
      // console.log(formdata);
      await this.getStateLists(formdata.DestinationCountry, "to");
      this.setState({
        originCountry: formdata.originCountry,
        originState: formdata.originState,
        originCity: formdata.originCity,
        originPostCode: formdata.originPostCode,

        DestinationCountry: formdata.DestinationCountry,
        destinationCity: formdata.destinationCity,
        destinationState: formdata.destinationState,
        destinationPostCode: formdata.destinationPostCode,

        parcelWeight: formdata.parcelWeight,
        parcelWidth: formdata.parcelWidth,
        parcelHeight: formdata.parcelHeight,
        parcelLength: formdata.parcelLength,

        packageType: formdata.packageType,
      });

      // localStorage.removeItem('parcel');
    }
  }
  componentWillUnmount() {
    // if (ValidatorForm.hasValidationRule("isValidPostCode")) {
    ValidatorForm.removeValidationRule("isValidPostCode");
    ValidatorForm.removeValidationRule("isValidShipPostCode");
    // }
    document.body.classList.remove("home");
    localStorage.removeItem("parcel");
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangePostCode(e) {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  }

  async onDestinationCountryChange(e) {
    this.onChange(e);

    const countryCode = e.target.value;
    // console.log(countryCode);
    await this.getStateLists(countryCode, "to");
    // if (stateCode === "CA" || stateCode === "US") {
    //   getStateLists(stateCode).then((res) => {
    //     if (res.status) {
    //       const data = res.data;
    //       this.setState({
    //         stateLists: data,
    //         destinationState: "",
    //         destinationPostCode: "",
    //       });
    //     }
    //   });
    // } else {
    //   this.setState({
    //     stateLists: [],
    //     destinationState: "",
    //     destinationPostCode: "",
    //   });
    // }
  }

  async getStateLists(countryCode, type) {
    if (countryCode === "CA" || countryCode === "US") {
      await getStateLists(countryCode).then((res) => {
        if (res.status) {
          const data = res.data;
          if (type === "from") {
            this.setState({
              originStateLists: data,
            });
          } else {
            this.setState({
              destinationStateLists: data,
              destinationState: "",
              destinationPostCode: "",
            });
          }
        }
      });
    } else {
      this.setState({
        destinationStateLists: [],
        destinationState: "",
        destinationPostCode: "",
      });
    }
  }

  onPackageTypeChange(e) {
    this.setState({ [e.target.name]: e.target.value, parcelWeight: 1 });
    // this.setState({ [e.target.name]: e.target.value, parcelWeight: 1, parcelWidth: '', parcelHeight: '', parcelLength : ''})
    // this.optionalFieldDisable = false;
    // if( e.target.value === '102' || e.target.value === '103' ){
    //     this.optionalFieldDisable = true;
    // }
  }

  getPostCodeValidationRegEx() {
    return this.canadaPostCodeRegEx;
  }

  // onpickUpCountryChange(e) {

  // }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isLoading: true });
    let form_data = {};
    if (event.target.length > 0) {
      for (var i = 0; i < event.target.length; i++) {
        const element = event.target[i];
        if (element.name !== "") {
          form_data[element.name] = element.value;
        }
      }
    }

    const selectedOriginCountry = this.state.countryLists.find(
      (country) => country.country_code === "CA"
    );

    const selectedOriginState = this.state.originStateLists.find(
      (state) => state.state_code === this.state.originState
    );

    form_data["originCountryInfo"] = selectedOriginCountry;
    form_data["originStateInfo"] = selectedOriginState;

    const selectedDestinationCountry = this.state.countryLists.find(
      (country) => country.country_code === this.state.DestinationCountry
    );

    let selectedDestinationState = this.state.destinationStateLists.find(
      (state) => state.state_code === this.state.destinationState
    );

    if (typeof selectedDestinationState === "undefined") {
      selectedDestinationState = {
        id: 0,
        state_code: this.state.destinationState,
        state: this.state.destinationState,
        country_code: this.state.DestinationCountry,
      };
    }

    form_data["destinationCountryInfo"] = selectedDestinationCountry;
    form_data["destinationStateInfo"] = selectedDestinationState;
    form_data["quick_quote"] = "yes";

    // const formdata = new FormData();
    // formdata.append('user_id', 0);
    // formdata.append('user_detail[first_name]', this.state.first_name);
    // formdata.append('user_detail[last_name]', this.state.last_name);
    // formdata.append('user_detail[business_name]', (this.state.selectedType === 'enterprise') ? this.state.business_name : '');
    // formdata.append('user_detail[phone]', this.state.phone_number);
    // formdata.append('user_detail[email]', this.state.email);

    // formdata.append('quick_quote', 'yes');

    // formdata.append('origin_postcode', form_data.originPostCode);
    // formdata.append('origin_country', form_data.originCountry);
    // formdata.append('origin_state', form_data.originState);
    // formdata.append('origin_city', form_data.originCity);

    // formdata.append('destination_postcode', form_data.destinationPostCode);
    // formdata.append('destination_country', form_data.DestinationCountry);
    // formdata.append('destination_state', form_data.destinationState);
    // formdata.append('destination_city', form_data.destinationCity);

    // formdata.append('parcel[weight]', form_data.parcelWeight);
    // formdata.append('parcel[width]', form_data.parcelWidth);
    // formdata.append('parcel[height]', form_data.parcelHeight);
    // formdata.append('parcel[length]', form_data.parcelLength);

    this.props.history.push({
      pathname: "/rates",
      data: form_data,
    });
  }

  render() {
    return (
      <>
        <div className="banner-wrapper">
          <div className="banner-caption py-3">
            <Container>
              <div className="banner-text my-md-5 py-md-3 d-md-block">
                <Row>
                  <Col
                    md={6}
                    className="align-items-center order-2 order-md-1 mt-5"
                  >
                    <h4 className="banner-sub-title text-white">
                      Reliable shipping is just the beginning.
                    </h4>
                    <h2 className="banner-title mb-md-3 text-white">
                      Intra-Canada Shipping
                    </h2>
                    <p className="banner-para mb-lg-5 text-white">
                      Shipeazy offers services, tools, and accessible retail
                      locations to help you create great delivery experiences
                      that drive repeat business, customer loyalty and sales
                      growth.
                    </p>
                  </Col>

                  <Col md={6} className="order-1 order-md-2">
                    {/* <Form action="/sendmail" method="post" noValidate> */}
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleSubmit}
                      // onError={errors => console.log(errors)}
                    >
                      <Card className="border-0 rounded-0 quote-form">
                        <CardHeader className="bg-transparent d-md-flex justify-content-between border-top">
                          <CardTitle tag="h6" className="mb-0">
                            Quick Quote
                          </CardTitle>
                        </CardHeader>

                        <CardBody className="p-3">
                          <h6 className="text-uppercase text-secondary small font-weight-bold mb-2">
                            Ship From
                          </h6>
                          <Row>
                            <Col md="6">
                              <FormGroup className="fancy-form-group">
                                <FormControl style={themeStyle.textField}>
                                  <SelectValidator
                                    label="Origin Country"
                                    value="CA"
                                    // disabled="true"
                                    onChange={this.onChange}
                                    validators={["required"]}
                                    errormessages={["this field is required"]}
                                    inputProps={{
                                      name: "originCountry",
                                      id: "originCountry",
                                      disabled: true,
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="US">
                                      United States
                                    </MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                  </SelectValidator>
                                </FormControl>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="fancy-form-group mb-1">
                                <TextValidator
                                  label="Origin Post Code"
                                  onChange={this.onChangePostCode}
                                  style={themeStyle.textField}
                                  name="originPostCode"
                                  id="originPostCode"
                                  value={this.state.originPostCode}
                                  validators={[
                                    "required",
                                    // `matchRegexp:${this.canadaPostCodeRegEx}`,
                                    "isValidShipPostCode",
                                  ]}
                                  errorMessages={[
                                    "this field is required",
                                    "PLease enter valid postcode.",
                                  ]}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup className="fancy-form-group">
                                <FormControl style={themeStyle.textField}>
                                  <SelectValidator
                                    label="Origin State"
                                    value={this.state.originState}
                                    onChange={this.onChange}
                                    validators={["required"]}
                                    errormessages={["this field is required"]}
                                    inputProps={{
                                      name: "originState",
                                      id: "originState",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {this.state.originStateLists.length > 0
                                      ? this.state.originStateLists.map(
                                          (option, _id) => (
                                            <MenuItem
                                              key={_id}
                                              value={option.state_code}
                                            >
                                              {option.state}
                                            </MenuItem>
                                          )
                                        )
                                      : ""}
                                  </SelectValidator>
                                </FormControl>
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup className="fancy-form-group mb-1">
                                <TextValidator
                                  label="Origin City"
                                  onChange={this.onChange}
                                  style={themeStyle.textField}
                                  name="originCity"
                                  id="originCity"
                                  value={this.state.originCity}
                                  validators={["required"]}
                                  errorMessages={["this field is required"]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <h6 className="text-uppercase text-secondary small font-weight-bold mb-2">
                            Ship To
                          </h6>
                          <Row>
                            <Col md="6">
                              <FormGroup className="fancy-form-group">
                                <FormControl style={themeStyle.textField}>
                                  <SelectValidator
                                    label="Destination Country"
                                    value={this.state.DestinationCountry}
                                    // disabled="true"
                                    onChange={this.onDestinationCountryChange}
                                    validators={["required"]}
                                    errormessages={["this field is required"]}
                                    inputProps={{
                                      name: "DestinationCountry",
                                      id: "DestinationCountry",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>

                                    {this.state.countryLists
                                      ? this.state.countryLists.map(
                                          (option, _id) => (
                                            <MenuItem
                                              key={_id}
                                              value={option.country_code}
                                            >
                                              {option.country_name}
                                            </MenuItem>
                                          )
                                        )
                                      : ""}

                                    {/* <MenuItem value="US">
                                      United States
                                    </MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem> */}
                                  </SelectValidator>
                                </FormControl>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="fancy-form-group mb-1">
                                <TextValidator
                                  label="Destination Post Code"
                                  onChange={this.onChangePostCode}
                                  style={themeStyle.textField}
                                  name="destinationPostCode"
                                  id="destinationPostCode"
                                  value={this.state.destinationPostCode}
                                  validators={[
                                    "required",
                                    "isValidPostCode",
                                    // `matchRegexp:${this.getPostCodeValidationRegEx()}`,
                                  ]}
                                  errorMessages={[
                                    "this field is required",
                                    "Please enter valid postcode.",
                                  ]}
                                />
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup className="fancy-form-group">
                                <FormControl style={themeStyle.textField}>
                                  {this.state.destinationStateLists.length >
                                  0 ? (
                                    <SelectValidator
                                      label="Destination State"
                                      value={this.state.destinationState}
                                      onChange={this.onChange}
                                      validators={["required"]}
                                      errormessages={["this field is required"]}
                                      inputProps={{
                                        name: "destinationState",
                                        id: "destinationState",
                                      }}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {this.state.destinationStateLists.length >
                                      0
                                        ? this.state.destinationStateLists.map(
                                            (option, _id) => (
                                              <MenuItem
                                                key={_id}
                                                value={option.state_code}
                                              >
                                                {option.state}
                                              </MenuItem>
                                            )
                                          )
                                        : ""}
                                      {/* {this.state.countryStates.length > 0
                                      ? this.state.countryStates.map(
                                          (option, _id) => (
                                            <MenuItem
                                              key={_id}
                                              value={option.abbreviation}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          )
                                        )
                                      : ""} */}
                                    </SelectValidator>
                                  ) : (
                                    <FormGroup className="fancy-form-group mb-1">
                                      <TextValidator
                                        label="Destination State"
                                        onChange={this.onChange}
                                        style={themeStyle.textField}
                                        name="destinationState"
                                        id="destinationState"
                                        value={this.state.destinationState}
                                        validators={["required"]}
                                        errorMessages={[
                                          "this field is required",
                                        ]}
                                      />
                                    </FormGroup>
                                  )}
                                </FormControl>
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup className="fancy-form-group mb-1">
                                <TextValidator
                                  label="Destination City"
                                  onChange={this.onChange}
                                  style={themeStyle.textField}
                                  name="destinationCity"
                                  id="destinationCity"
                                  value={this.state.destinationCity}
                                  validators={["required"]}
                                  errorMessages={["this field is required"]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <h6 className="text-uppercase text-secondary small font-weight-bold mb-2">
                                Package Details
                              </h6>

                              <FormGroup className="fancy-form-group">
                                <FormControl style={themeStyle.textField}>
                                  <SelectValidator
                                    label="Package Type"
                                    value={this.state.packageType}
                                    onChange={this.onPackageTypeChange}
                                    validators={["required"]}
                                    errormessages={["this field is required"]}
                                    inputProps={{
                                      name: "packageType",
                                      id: "packageType",
                                    }}
                                  >
                                    <MenuItem value="101">Select</MenuItem>
                                    <MenuItem value="102">Envelope</MenuItem>
                                    <MenuItem value="103">Package</MenuItem>
                                  </SelectValidator>
                                </FormControl>
                              </FormGroup>

                              <FormGroup className="fancy-form-group">
                                <TextValidator
                                  label="Weight"
                                  onChange={this.onChange}
                                  style={themeStyle.textField}
                                  name="parcelWeight"
                                  id="parcelWeight"
                                  value={this.state.parcelWeight}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        lb
                                      </InputAdornment>
                                    ),
                                  }}
                                  validators={[
                                    "required",
                                    `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`,
                                  ]}
                                  errorMessages={[
                                    "this field is required",
                                    "Only numeric or decimal allowed.",
                                  ]}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <h6 className="text-uppercase text-secondary small font-weight-bold mb-2">
                                &nbsp;
                              </h6>

                              <Row style={{ position: "relative" }}>
                                {/* {(this.optionalFieldDisable) ? <div className="down-opacity"></div> : '' } */}
                                <Col md={6}>
                                  <FormGroup className="fancy-form-group">
                                    <TextValidator
                                      label="Width"
                                      onChange={this.onChange}
                                      style={themeStyle.textField}
                                      name="parcelWidth"
                                      id="parcelWidth"
                                      value={this.state.parcelWidth}
                                      validators={[
                                        "required",
                                        `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`,
                                      ]}
                                      errorMessages={[
                                        "this field is required",
                                        "Only numeric or decimal allowed.",
                                      ]}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            in
                                          </InputAdornment>
                                        ),
                                        disabled: this.optionalFieldDisable,
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={6}>
                                  <FormGroup className="fancy-form-group">
                                    <TextValidator
                                      label="Height"
                                      onChange={this.onChange}
                                      style={themeStyle.textField}
                                      name="parcelHeight"
                                      id="parcelHeight"
                                      value={this.state.parcelHeight}
                                      validators={[
                                        "required",
                                        `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`,
                                      ]}
                                      errorMessages={[
                                        "this field is required",
                                        "Only numeric or decimal allowed.",
                                      ]}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            in
                                          </InputAdornment>
                                        ),
                                        disabled: this.optionalFieldDisable,
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup className="fancy-form-group">
                                    <TextValidator
                                      label="Length"
                                      onChange={this.onChange}
                                      style={themeStyle.textField}
                                      name="parcelLength"
                                      id="parcelLength"
                                      value={this.state.parcelLength}
                                      validators={[
                                        "required",
                                        `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`,
                                      ]}
                                      errorMessages={[
                                        "this field is required",
                                        "Only numeric or decimal allowed.",
                                      ]}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            in
                                          </InputAdornment>
                                        ),
                                        disabled: this.optionalFieldDisable,
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <FormGroup className="fancy-form-group mt-2">
                            <Button
                              type="submit"
                              color="success"
                              className="btn-block"
                              disabled={this.state.isLoading}
                            >
                              {this.state.isLoading
                                ? "Loading…"
                                : "Get A Quote"}
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </ValidatorForm>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        <section className="trusted-company bg-light py-4">
          <Container>
            <Row>
              <Col md={6}>
                <h6 className="text-uppercase">They’re All Here</h6>
                <div className="company-logos">
                  <div className="row no-gutters">
                    <Col>
                      <img
                        src={require("../../../assets/images/assets/fedex-logo.png")}
                        className="img-fluid"
                        alt="fedex"
                      />
                    </Col>
                    <Col>
                      <img
                        src={require("../../../assets/images/assets/purolator-logo.png")}
                        className="img-fluid"
                        alt="purolator"
                      />
                    </Col>
                    <Col>
                      <img
                        src={require("../../../assets/images/assets/canada-post-logo.png")}
                        className="img-fluid"
                        alt="canada post"
                      />
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default withRouter(Banner);
