import React from "react";
import { checkJWToken, getDecodedJWToken } from '../../functions/Auth';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import themeStyle from '../components/general/ThemeStyle';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroup,
    Button
} from "reactstrap";

import Header from "../components/general/Header";
import UserSidebar from "./UserSidebar";
import Footer from "../components/general/Footer";
import AlertMessages from "../components/general/AlertMessages";

import { changePassword } from "../../functions/userRequests";

class ChangePassword extends React.Component {

    constructor() {
        super();
        this.state = {
            showMessage: '',
            isLoading: false,
            messageColor: '',
            totalRows: null,
            orders: [],
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            currentUser: [],
        }

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.body.classList.add('change-password');

        if (!checkJWToken()) {
            this.props.history.push('/login');
        }

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.newPassword) {
                return false;
            }
            return true;
        });

        this.setState({
            currentUser: getDecodedJWToken()
        });
    }
    componentWillUnmount() {
        document.body.classList.remove('change-password');
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit( e ){
        e.preventDefault();

        const passwordData = {
            user_id: this.state.currentUser.userId,
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword
        }

        this.setState({isLoading: true});
        changePassword(passwordData).then(res => {
            if(res){
                this.setState({ 
                    messageColor: (res.status) ? 'success' : 'danger', 
                    showMessage: res.message,
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                 });
                setTimeout(function(){
                    this.setState({ messageColor: '', showMessage: '' });
                }.bind(this),5000);
                this.setState({isLoading: false});
            }
        })
    }

    render() {

        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">My Account</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row>

                            <UserSidebar location={this.props.location} />

                            <Col md={8}>
                                <div className="sidebar-widgets">

                                {this.state.showMessage !== "" ?
                                        <AlertMessages color={this.state.messageColor} message={this.state.showMessage} />
                                        : ''}

                                    <Card className="border-0 rounded-0 mb-4">
                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h5" className="mb-0">Change Password</CardTitle>
                                        </CardHeader>
                                        <CardBody className="p-4">

                                            <ValidatorForm
                                                ref="form"
                                                onSubmit={this.handleSubmit}
                                                onError={errors => console.log(errors)}
                                            >
                                                <FormGroup className="fancy-form-group">
                                                    <TextValidator
                                                        label="Old Password"
                                                        onChange={this.onChange}
                                                        style={themeStyle.textField}
                                                        name="oldPassword"
                                                        id="oldPassword"
                                                        type="password"
                                                        value={this.state.oldPassword}
                                                        validators={['required']}
                                                        errorMessages={['this field is required']}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="fancy-form-group">
                                                    <TextValidator
                                                        label="New Password"
                                                        onChange={this.onChange}
                                                        style={themeStyle.textField}
                                                        name="newPassword"
                                                        id="newPassword"
                                                        type="password"
                                                        value={this.state.newPassword}
                                                        validators={['required']}
                                                        errorMessages={['this field is required']}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="fancy-form-group">
                                                    <TextValidator
                                                        label="Confirm New Password"
                                                        onChange={this.onChange}
                                                        style={themeStyle.textField}
                                                        name="confirmNewPassword"
                                                        id="confirmNewPassword"
                                                        type="password"
                                                        value={this.state.confirmNewPassword}
                                                        validators={['required', 'isPasswordMatch']}
                                                        errorMessages={['this field is required', 'Confirm Password not matched with password.']}
                                                    />
                                                </FormGroup>

                                                <div className="pt-3">
                                                <Button
                                                    color="success"
                                                    className="btn-block"
                                                    disabled={this.state.isLoading}
                                                >
                                                    {this.state.isLoading ? 'Loading…' : 'Update'}
                                                </Button>
                                                </div>
                                            </ValidatorForm>
                                        </CardBody>

                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default ChangePassword;