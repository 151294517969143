import React from "react";
import { checkJWToken, getDecodedJWToken } from '../../functions/Auth';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextValidator, SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import themeStyle from '../components/general/ThemeStyle';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getState } from "../../functions/APIRequest";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Row,
    Button
} from "reactstrap";

import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
//import OrderProcessSidebar from "./OrderProcessSidebar";
import AutoCompleteAddress from "../components/general/AutoCompleteAddress";

import { getTimeSlots } from "../../functions/APIRequest";


class CreateOrder extends React.Component {

    canadaPostCodeRegEx = '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\s?[0-9][A-Z][0-9]$';
    constructor() {
        super();
        this.state = {
            showMessage: "",
            isLoading: false,
            messageColor: "",
            senderFirstName: "",
            senderLastName: "",
            senderPickUpFrom: "",
            senderPickUpTimeSlot: "",
            pickUpCountry: "",
            pickUpAddress: "",
            receiverFirstName: "",
            receiverLastName: "",
            receiverPhone: "",
            dropOffCountry: "",
            dropOffAddress: "",
            parcelWeight: "",
            parcelWidth: "",
            parcelHeight: "",
            parcelLength: "",
            orders: [],
            errors: {},
            pickUpPlaces: "",
            timeSlots: "",
            loginData: {},



            originCountry: '',
            originState: '',
            originCity: '',
            originPostCode: '',
            originPostCodeError: false,

            allStates: {},
            countryStates: [],

            DestinationCountry: '',
            destinationCity: '',
            destinationState: '',
            destinationPostCode: '',

            packageType: 101,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.body.classList.add('create-orders-process');

        getTimeSlots().then(res => {
            if (res.status) {
                const data = res.data;
                this.setState({
                    pickUpPlaces: data.pickup_place,
                    timeSlots: data.timeSlots,
                });
            }
        })

        getState().then(res => {
            this.setState({ allStates: res });
            const country = 'CA';
            const selectedStates = (this.state.allStates[country] !== "undefined") ? this.state.allStates[country] : [];
            
            this.setState({ countryStates: selectedStates });

            const formdata = JSON.parse(localStorage.getItem('parcel'));
            if(typeof formdata !== 'undefined' && formdata !== null) {
                this.setState( {
                    originCountry: formdata.originCountry,
                    originState: formdata.originState,
                    originCity: formdata.originCity,
                    originPostCode: formdata.originPostCode,
                    
                    DestinationCountry: formdata.DestinationCountry,
                    destinationCity: formdata.destinationCity,
                    destinationState: formdata.destinationState,
                    destinationPostCode: formdata.destinationPostCode,

                    parcelWeight: formdata.parcelWeight,
                    parcelWidth: formdata.parcelWidth,
                    parcelHeight: formdata.parcelHeight,
                    parcelLength: formdata.parcelLength,

                    packageType: formdata.packageType
                });
            }
        })

        this.validate();
        if (checkJWToken()) {
            const currentUser = getDecodedJWToken();
            this.setState({ loginData: currentUser, senderFirstName: currentUser.first_name, senderLastName: currentUser.last_name })
        }

        
    }
    componentWillUnmount() {
        document.body.classList.remove('create-orders-process');
        localStorage.removeItem('parcel');
    }

    onChange(e) {
        const validType = (typeof e.target.dataset !== "undefined") ? e.target.dataset.valid : '';
        if (validType !== '') {
            
            switch (validType) {
                case 'char':
                    this.validCharOnly(e)
                    break;

                    case 'float':
                    this.validNumericWithDecimalcOnly(e)
                    break;

                case 'numeric':
                    this.validNumericOnly(e)
                    break;

                default:
                    this.setState({ [e.target.name]: e.target.value });
                    break;
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
        this.validate();
    }

    validCharOnly(e) {
        const re = /^[a-zA-Z][a-zA-Z\s]*$/;
        if (re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    validNumericOnly(e) {
        const re = /^[0-9\b]+$/;
        if (re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    validNumericWithDecimalcOnly(e){
        const re = /^[0-9]+\.?[0-9]*$/;
        if (re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ isLoading: true });
        let form_data = {};
        if (event.target.length > 0) {
            for (var i = 0; i < event.target.length; i++) {
                const element = event.target[i];
                if (element.name !== "") {
                    form_data[element.name] = element.value;
                }
            }
        }

        form_data['quick_quote'] = 'yes';
        this.props.history.push({
            pathname: '/rates',
            data: form_data
        })

    }

    validate() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (value.trim() === '') {
                return false;
            }
            return true;
        });
    }

    render() {

        return (
            <>
                <Header />

                <div className="pageheader-wrapper py-5">
                    <Container>
                        <h2 className="banner-title mb-md-3 text-white">Order Process</h2>
                    </Container>
                </div>

                <section className="content-wrapper py-5">
                    <Container>
                        <Row className="justify-content-center">

                            <Col md={8}>
                                <Card className="border-0 rounded-0 mb-4 h-100">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                        onError={errors => console.log(errors)}
                                    >


                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h6" className="mb-0">Ship From</CardTitle>
                                        </CardHeader>

                                        <CardBody>
                                            <Row>

                                                <Col md="6">
                                                    <FormGroup className="fancy-form-group">
                                                        <FormControl style={themeStyle.textField}>
                                                            <SelectValidator
                                                                label="Origin Country"
                                                                value="CA"
                                                                // disabled="true"
                                                                onChange={this.onChange}
                                                                validators={['required']}
                                                                errormessages={['this field is required']}
                                                                inputProps={{
                                                                    name: 'originCountry',
                                                                    id: 'originCountry',
                                                                    disabled: true
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="US">
                                                                    United States
                        </MenuItem>
                                                                <MenuItem value="CA">
                                                                    Canada
                        </MenuItem>
                                                            </SelectValidator>
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="fancy-form-group mb-1">
                                                        <TextValidator
                                                            label="Origin Post Code"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="originPostCode"
                                                            id="originPostCode"
                                                            value={this.state.originPostCode}
                                                            validators={['required', `matchRegexp:${this.canadaPostCodeRegEx}`]}
                                                            errorMessages={['this field is required', 'PLease enter valid postcode.']}
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup className="fancy-form-group">
                                                        <FormControl style={themeStyle.textField}>
                                                            <SelectValidator
                                                                label="Origin State"
                                                                value={this.state.originState}
                                                                onChange={this.onChange}
                                                                validators={['required']}
                                                                errormessages={['this field is required']}
                                                                inputProps={{
                                                                    name: 'originState',
                                                                    id: 'originState',
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {(this.state.countryStates.length > 0) ?
                                                                    this.state.countryStates.map((option, _id) =>
                                                                        <MenuItem key={_id} value={option.abbreviation}>{option.name}</MenuItem>
                                                                    )
                                                                    : ''}
                                                            </SelectValidator>
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup className="fancy-form-group mb-1">
                                                        <TextValidator
                                                            label="Origin City"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="originCity"
                                                            id="originCity"
                                                            value={this.state.originCity}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </CardBody>


                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h6" className="mb-0">Ship To</CardTitle>
                                        </CardHeader>

                                        <CardBody>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="fancy-form-group">
                                                        <FormControl style={themeStyle.textField}>
                                                            <SelectValidator
                                                                label="Destination Country"
                                                                value="CA"
                                                                // disabled="true"
                                                                onChange={this.onChange}
                                                                validators={['required']}
                                                                errormessages={['this field is required']}
                                                                inputProps={{
                                                                    name: 'DestinationCountry',
                                                                    id: 'DestinationCountry',
                                                                    disabled: true
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="US">
                                                                    United States
                                                                                </MenuItem>
                                                                <MenuItem value="CA">
                                                                    Canada
                                                                                </MenuItem>
                                                            </SelectValidator>
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="fancy-form-group mb-1">
                                                        <TextValidator
                                                            label="Destination Post Code"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="destinationPostCode"
                                                            id="destinationPostCode"
                                                            value={this.state.destinationPostCode}
                                                            validators={['required', `matchRegexp:${this.canadaPostCodeRegEx}`]}
                                                            errorMessages={['this field is required', 'PLease enter valid postcode.']}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup className="fancy-form-group">
                                                        <FormControl style={themeStyle.textField}>
                                                            <SelectValidator
                                                                label="Destination State"
                                                                value={this.state.destinationState}
                                                                onChange={this.onChange}
                                                                validators={['required']}
                                                                errormessages={['this field is required']}
                                                                inputProps={{
                                                                    name: 'destinationState',
                                                                    id: 'destinationState',
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {(this.state.countryStates.length > 0) ?
                                                                    this.state.countryStates.map((option, _id) =>
                                                                        <MenuItem key={_id} value={option.abbreviation}>{option.name}</MenuItem>
                                                                    )
                                                                    : ''}
                                                            </SelectValidator>
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup className="fancy-form-group mb-1">
                                                        <TextValidator
                                                            label="Destination City"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="destinationCity"
                                                            id="destinationCity"
                                                            value={this.state.destinationCity}
                                                            validators={['required']}
                                                            errorMessages={['this field is required']}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                        </CardBody>


                                        <CardHeader className="bg-transparent d-md-flex justify-content-between">
                                            <CardTitle tag="h6" className="mb-0">PACKAGE DETAILS</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>

                                                    <FormGroup className="fancy-form-group">
                                                        <FormControl style={themeStyle.textField}>
                                                            <SelectValidator
                                                                label="Package Type"
                                                                value={this.state.packageType}
                                                                onChange={this.onPackageTypeChange}
                                                                validators={['required']}
                                                                errormessages={['this field is required']}
                                                                inputProps={{
                                                                    name: 'packageType',
                                                                    id: 'packageType',
                                                                }}
                                                            >
                                                                <MenuItem value="101">My Package</MenuItem>
                                                                <MenuItem value="102">Envelope</MenuItem>
                                                                <MenuItem value="103">Package</MenuItem>
                                                            </SelectValidator>
                                                        </FormControl>
                                                    </FormGroup>

                                                    <FormGroup className="fancy-form-group">
                                                        <TextValidator
                                                            label="Weight"
                                                            onChange={this.onChange}
                                                            style={themeStyle.textField}
                                                            name="parcelWeight"
                                                            id="parcelWeight"
                                                            value={this.state.parcelWeight}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="start">lb</InputAdornment>,
                                                            }}
                                                            validators={['required', `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`]}
                                                            errorMessages={['this field is required', 'Only numeric or decimal allowed.']}
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col md={6}>

                                                    <Row style={{ position: 'relative' }}>
                                                        {/* {(this.optionalFieldDisable) ? <div className="down-opacity"></div> : '' } */}
                                                        <Col md={6}>
                                                            <FormGroup className="fancy-form-group">
                                                                <TextValidator
                                                                    label="Width"
                                                                    onChange={this.onChange}
                                                                    style={themeStyle.textField}
                                                                    name="parcelWidth"
                                                                    id="parcelWidth"
                                                                    value={this.state.parcelWidth}
                                                                    validators={['required', `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`]}
                                                                    errorMessages={['this field is required', 'Only numeric or decimal allowed.']}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                                                        disabled: this.optionalFieldDisable
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup className="fancy-form-group">
                                                                <TextValidator
                                                                    label="Height"
                                                                    onChange={this.onChange}
                                                                    style={themeStyle.textField}
                                                                    name="parcelHeight"
                                                                    id="parcelHeight"
                                                                    value={this.state.parcelHeight}
                                                                    validators={['required', `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`]}
                                                                    errorMessages={['this field is required', 'Only numeric or decimal allowed.']}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                                                        disabled: this.optionalFieldDisable
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup className="fancy-form-group">
                                                                <TextValidator
                                                                    label="Length"
                                                                    onChange={this.onChange}
                                                                    style={themeStyle.textField}
                                                                    name="parcelLength"
                                                                    id="parcelLength"
                                                                    value={this.state.parcelLength}
                                                                    validators={['required', `matchRegexp:^[0-9]+(\.[0-9]{1,2})?$`]}
                                                                    errorMessages={['this field is required', 'Only numeric or decimal allowed.']}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="start">in</InputAdornment>,
                                                                        disabled: this.optionalFieldDisable
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Col>

                                            </Row>
                                        </CardBody>


                                        <Col md={12} className="pt-3">
                                            <Button
                                                type="submit"
                                                color="success"
                                                className="btn-block"
                                                disabled={this.state.isLoading}
                                            >
                                                {this.state.isLoading ? 'Loading…' : 'Continue'}
                                            </Button>
                                        </Col>

                                    </ValidatorForm>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </section>

                <Footer />
            </>
        );
    }
}

export default CreateOrder;