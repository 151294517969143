import React from "react";
import {
    Container,
} from "reactstrap";

import OwlCarousel from "react-owl-carousel";
/*import 'owl.carousel/dist/assets/owl.carousel.css';*/
/*import 'owl.carousel/dist/assets/owl.theme.default.css';*/
import "assets/css/owl.carousel.min.css";


function Partners() {

    const responseiveParams = {"0" : {"items" : 2 }, "600" : {"items":4}, "1000" : {"items":5} };

    return (
        <>

            <section className="client-section bg-light py-2 py-md-5">
                <Container>

                    <div className="title mb-2 mb-md-4 text-center">
                        <h2 className="title-text">As Seen On...</h2>
                        <p className="lead">They love it. Read what our customers had to say!</p>
                    </div>

                    <div className="partners-slider py-4">
                        <OwlCarousel className="partners-carousel owl-theme px-4" loop  margin={15} stagePadding={10} autoplay dots={0} responsive={responseiveParams}>

                            <div className="item">
                                <div className="partners-block"><img src={require("../../../assets/images/assets/partner01.png")} alt="partners" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="partners-block"><img src={require("../../../assets/images/assets/partner02.png")} alt="partners" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="partners-block"><img src={require("../../../assets/images/assets/partner03.png")} alt="partners" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="partners-block"><img src={require("../../../assets/images/assets/partner04.png")} alt="partners" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="partners-block"><img src={require("../../../assets/images/assets/partner05.png")} alt="partners" />
                                </div>
                            </div>

                        </OwlCarousel>
                    </div>


                </Container>
            </section>

        </>
    );
};

export default Partners;